import React from 'react'
import { Modal } from 'react-bootstrap'
import { fee } from '../../../helper/api_url'
import { useModalHandler } from '../../../helper/custom_hook';

export function UpdateFeeModal({ data, apiHandler }) {
    const { toggleModal, status } = useModalHandler();
    return (
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-success me-1'><i className="ri-pencil-fill"></i></button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Update {data.name} Fee</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={e => apiHandler.handleUpdate(e, fee.update, toggleModal)}>
                        <input type="hidden" name="fee_id" defaultValue={data.id} />
                        <div className="row g-3">
                            <div className="col-12">
                                <div>
                                    <label htmlFor="feeName" className="form-label">Fee Name</label>
                                    <input type="text" className="form-control" id='feeName' name="name" defaultValue={data?.name} placeholder="Enter Fee Name" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="amount" className="form-label">Fee Amount</label>
                                    <input type="number" className="form-control" id='amount' name="amount" defaultValue={data?.amount} placeholder="Enter Fee Amount" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="type" className="form-label">Fee Type</label>
                                    <select className='form-control' name="type">
                                        {data.type && <option defaultValue={data.type} >{data.type}</option>}
                                        <option value='one-time' >One Time</option>
                                        <option value='monthly' >Monthly</option>
                                        <option value='annual' >Yearly</option>
                                        <option value='bi-annual' >Half-Yearly</option>
                                        <option value='quaterly' >Quaterly</option>
                                        <option value='daily' >Daily</option>
                                        <option value='weekly' >Weekly</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-12">
                                <div>
                                    <label htmlFor="priority" className="form-label">Description</label>
                                    <textarea className='form-control' name='description' defaultValue={data.description}></textarea>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-4">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                    <button type="submit" className="btn btn-primary">update</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    );
}
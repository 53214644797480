import React, { useEffect, useMemo} from 'react'
import BreadCrumb from "../../../components/common/BreadCrumb";
import { Card, CardBody, CardHeader, Col, Row, Button } from "react-bootstrap";
import { TableResponsive } from "../../../components/common/TableResponsive";
import { NewCourseModal} from './NewCourseModal';
import { course, fee} from '../../../helper/api_url';
import { usePageInitialtor } from '../../../helper/custom_hook';
import { useNavigate } from 'react-router-dom';
function HomeWorkClassWise() {
    const {tableData,apiHandler} = usePageInitialtor(course);
    const navigate = useNavigate();
    const navigatehandleHomework  =  (classId) => navigate('/home-work/'+classId)
    const columns = useMemo(()=>[
        {Header: "SrNo",accessor: "id", Cell: cell => {return (<p className="">{cell.row.index+1}</p>)},HeaderClass:'text-center', DataClass:'text-center'},
        {Header: "Class Name",accessor: "name",HeaderClass:'text-center', DataClass:''},
        {Header: "Section",HeaderClass:'text-center', DataClass:'text-center',Cell:cell=>cell.row.original.section.toUpperCase()},
        {
            Header: "Action",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell: (cell) => {
              return ( 
                <div className="">
                    <button onClick={e=>navigatehandleHomework(cell.row.original.id)} className='btn btn-sm btn-soft-info me-1'>
                        <i className="ri-eye-fill" /> View Homework
                    </button>
                </div>
              )
            },
        },
        {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                const bs_colors = ['primary','info','warning','danger','success','light','secondary'];
                const badge_class = row.id<=6 ? `badge-outline-${bs_colors[row.id]}`:`badge-S-${bs_colors[row.id%6]}`;
                return (
                <div className="d-flex">
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                            <a href="#" className="link text-dark"></a>
                          
                                <a href="#">{row.name}</a>
                        
                        </h5>
                        <p className="text-muted mb-0"></p>
                    </div>
                    <div className="flex-shrink-0">
                        <div>
                        <button onClick={e=>navigatehandleHomework(row.id)} className='btn btn-sm btn-soft-info me-1'>
                        <i className="ri-eye-fill" /> View Homework
                    </button>
                       
                        </div>
                    </div>
                </div>
                )
            }
        }
    ]);
    return (
        <>
            <BreadCrumb title="Users" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Class List</h5>
                            <NewCourseModal apiHandler={apiHandler} />
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive columns={columns} data={tableData} noRecord="No Record Course List" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default HomeWorkClassWise
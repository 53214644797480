import React, { useEffect, useMemo, useState } from 'react'
import { ASSET_URL, course } from '../../../../helper/api_url';
import { swal } from '../../../../helper/swal';
import { TableResponsive } from '../../../../components/common/TableResponsive';
import { FeeButton } from '../../Accounts/FeeButton';
import { ViewUserModal } from '../../AllUser/ViewUserModal';
import { UpdateAllUserModal } from '../../AllUser/UpdateAllUserModal';

function StudentList(props) {
    const { course_id, activeTab } = props;
    const [studentList, setStudentList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    useEffect(() => {
        setTableLoading(true)
        if (course_id && activeTab === 0) {
            course.students(course_id).then(res => setStudentList(res.data.student))
                .catch(e => swal.error(e.response ? e.response.data.message : e.message))
                .finally(() => setTableLoading(false))
        }
    }, [activeTab])
    const columns = useMemo(() => [
        { Header: "SrNo", accessor: "id", Cell: cell => { return (<p className="">{cell.row.index + 1}</p>) }, HeaderClass: 'text-center', DataClass: 'text-center' },
        {
            Header: "Name",
            accessor: "first_name",
            HeaderClass: '',
            DataClass: '',
            Cell: (cell) => {
                const imageUrl = ASSET_URL + cell.row.original.avatar;
                return (<span> <img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={imageUrl} alt="User Avatar" />{cell.row.original.first_name} {cell.row.original.last_name}</span>)
            }
        },
        { Header: "Gender", accessor: "gender", Cell: cell => { return (<p className="">{cell.row.original.gender.toUpperCase()}</p>) }, HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: "DOB", accessor: "dob", HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: "Email", accessor: "email", HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: "Phone", accessor: "phone", HeaderClass: 'text-center', DataClass: 'text-center' },
        {
            Header: "Action", Cell: (cell) => <FeeButton id={cell.row.original.id} />
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                const imageUrl = ASSET_URL + row.avatar;
                return (
                    <div className="d-flex">
                        {/* <ViewUserModal data={row}>
                        </ViewUserModal> */}
                        <img className="me-2 rounded-circle header-profile-user" src={imageUrl} alt="User Avatar" />
                        <div className="flex-grow-1" data-id="1">
                            <h5 className="fs-13 mb-1">
                                <a href="#" className="link text-dark"></a>
                                <a href="#">{row.first_name} {row.last_name}</a>
                            </h5>
                            <p className="text-muted mb-0"><a href={`tel:${row.phone}`}><i className='bx bx-phone text-success' />{row.phone}</a> | <i className='bx bx-mail-send' />{row.email}</p>
                        </div>
                        <div className="flex-shrink-0">
                            <FeeButton id={row.id} />
                        </div>
                    </div>
                )
            }
        }
    ], [])
    return (
        <TableResponsive isLoading={tableLoading} columns={columns} data={studentList} />
    )
}

export default StudentList
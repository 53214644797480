import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Nav, NavItem, Row, Tab } from 'react-bootstrap'
import { NavLink, useParams } from 'react-router-dom'
import StudentList from './TabPanes/StudentList';
import CourseAttendance from './TabPanes/CourseAttendance';
import { course } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import StudentFees from './TabPanes/StudentFees';
import { HomeWork } from './HomeWork';

function CourseProfile() {
    const [tabIndex,setTabIndex] = useState(null);
    const inactive_tab_classes = "d-inline-block text-center bg-soft-primary w-100 px-4 py-2 rounded";
    const active_tab_classes = "d-inline-block text-center bg-primary text-white w-100 px-4 py-2 rounded";
    const changeTab = (index) => setTabIndex(index);
    const {class_id} = useParams();
    const [currentCourse,setCurrentCourse]=useState({});
    const [currentCourseStudents,setCurrentCourseStudents]=useState([]);
    useEffect(()=>{
        if(class_id){
            course.select(class_id).then(res=>setCurrentCourse(res.data.course))
            .catch(e=>swal.error('Something went Wrong',e.response?e.response.data.message:e.message));
            course.students(class_id).then(res=>setCurrentCourseStudents(res.data.student))
            .catch(e=>swal.error('Something went Wrong',e.response?e.response.data.message:e.message))
            .finally(()=>setTabIndex(0));
        }
    },[class_id])
    return (
        <>
            <Row xs={12} className='d-lg-flex justify-content-between align-items-center'>
                <Col xs={12} md={6}>
                    <div className='d-flex align-items-center justify-content-center mb-4'>
                        <div className='p-3 rounded-circle border d-flex justify-content-center align-items-center bg-soft-success' style={{ aspectRatio: '1/1',objectFit:'contain', maxWidth:'92px' }}>
                            <p className='m-0 nowrap'>{currentCourse?.name}</p>
                        </div>
                        <div className='ms-3'>
                            <div className='text-nowrap'>
                                <span className='fw-bold me-2'>Class Teacher :</span> <span> teacher name</span>
                            </div>
                            <div className='text-nowrap'>
                                <span className='fw-bold me-2'>Monitor :</span> <span>student name</span>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col xs={12} md={6}>
                    <ul className='list-group list-group-flush'>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                            <span className='text-nowrap me-4'>Total Students</span>
                            <span>{currentCourseStudents?.length}</span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                            <span className='text-nowrap me-4'>Present Students</span>
                            <span>-</span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                            <span className='text-nowrap me-4'>Absent Students</span>
                            <span>-</span>
                        </li>
                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                            <span className='text-nowrap me-4'>Students on Leave</span>
                            <span>-</span>
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col xs={12}>    
                    <Card>
                        <CardHeader>
                            <Nav variant='' className="d-flex justify-content-between align-items-center px-4">
                                <NavItem className="mb-2" style={{maxWidth:'164px'}}>
                                    <NavLink onClick={e=>changeTab(0)} className={tabIndex===0?active_tab_classes:inactive_tab_classes}>Student List</NavLink>
                                </NavItem>
                                <NavItem className="mb-2" style={{maxWidth:'164px'}}>
                                    <NavLink onClick={e=>changeTab(1)} className={tabIndex===1?active_tab_classes:inactive_tab_classes}>Time Table</NavLink>
                                </NavItem>
                                <NavItem className="mb-2" style={{maxWidth:'164px'}}>
                                    <NavLink onClick={e=>changeTab(2)} className={tabIndex===2?active_tab_classes:inactive_tab_classes}>Homeworks</NavLink>
                                </NavItem>
                                <NavItem className="mb-2" style={{maxWidth:'164px'}}>
                                    <NavLink onClick={e=>changeTab(3)} className={tabIndex===3?active_tab_classes:inactive_tab_classes}>Fees</NavLink>
                                </NavItem>
                                <NavItem className="mb-2" style={{maxWidth:'164px'}}>
                                    <NavLink onClick={e=>changeTab(4)} className={tabIndex===4?active_tab_classes:inactive_tab_classes}>Attendance</NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <CardBody>
                            <Tab.Container activeKey={tabIndex}>
                                <Tab.Content>
                                <Tab.Pane eventKey={0}><StudentList activeTab={tabIndex} course_id={currentCourse?.id}/></Tab.Pane>
                                <Tab.Pane eventKey={1}>Time table</Tab.Pane>
                                <Tab.Pane eventKey={2}>
                                    <HomeWork  class_id={class_id}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey={3}><StudentFees activeTab={tabIndex} course_id={currentCourse?.id} /></Tab.Pane>
                                <Tab.Pane eventKey={4}><CourseAttendance activeTab={tabIndex} course_id={currentCourse?.id} /></Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
        
    )
}

export default CourseProfile
import React from 'react'
import { Row,Col } from 'react-bootstrap'
export const CourseDetail = ({courses}) => {
    return (
        <>
            <div className="tab-pane fade" id="projects" role="tabpanel">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            {
                                courses?.map((item, idx) => (
                                    <div className="col-xxl-3 col-sm-6" key={idx}>
                                        <div className="card profile-project-card shadow-none profile-project-warning">
                                            <div className="card-body p-4">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1 text-muted overflow-hidden">
                                                        <h5 className="fs-14 text-truncate"><a href="#" className="text-dark">{item.course_name}</a></h5>
                                                        <ul className="list-group">
                                                            {/* <li className='mb-3'>
                                                                <Row>
                                                                    <Col xs={5}><span className='text-muted text-truncate mb-0'>Type </span></Col>
                                                                    <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='fw-semibold text-dark text-wrap'>{item.type}  </span>
                                                                    </Col>
                                                                </Row>
                                                            </li> */}
                                                            <li className='mb-3'>
                                                                <Row>
                                                                    <Col xs={5}><span className='text-muted text-truncate mb-0'>Class</span></Col>
                                                                    <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='fw-semibold text-dark text-wrap'>{item.code}  </span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            {/* <li className='mb-3'>
                                                                <Row>
                                                                    <Col xs={5}><span className='text-muted text-truncate mb-0'>Name</span></Col>
                                                                    <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='fw-semibold text-dark text-wrap'>{item.name}  </span>
                                                                    </Col>
                                                                </Row>
                                                            </li> */}
                                                            <li className='mb-3'>
                                                                <Row>
                                                                    <Col xs={5}><span className='text-muted text-truncate mb-0'>Eligibility</span></Col>
                                                                    <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='fw-semibold text-dark text-nowrap'>{(item.min_eligibility).toUpperCase()}  </span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='mb-3'>
                                                                <Row>
                                                                    <Col xs={5}><span className='text-muted text-truncate mb-0'>Semester</span></Col>
                                                                    <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='fw-semibold text-dark text-wrap'>{item.semesters}  Sem</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='mb-3'>
                                                                <Row>
                                                                    <Col xs={5}><span className='text-muted text-truncate mb-0'>Duration</span></Col>
                                                                    <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='fw-semibold text-dark text-wrap'>{item.sem_duration}  Month</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                        </ul>
                                                        <div className='text-center'>
                                                            <button className='btn btn-sm btn-outline-success px-5'>View  Detailed </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

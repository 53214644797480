export const current_dues = row =>{
    const currentDate = new Date();
    return row.due_fee.reduce((total,fee)=>{
        const startDate = new Date(fee.starting_date);
        const current_installment = fee.frequency_type == 'monthly'
        ?(currentDate.getFullYear() - startDate.getFullYear()) * 12 + (currentDate.getMonth() - startDate.getMonth()) + (currentDate.getDate() > 5 ? 1:0)
        :fee.total_installments;
        const paid_fee = fee.fees_payment?.reduce((acc,pmnt)=>acc+parseFloat(pmnt.paid_amt)+parseFloat(pmnt.discount),0);
        return total + (fee.imposed_amount * parseInt(current_installment)) - paid_fee;
    },0);
}   

export const given_fee_current_dues = fee => {
    const currentDate = new Date();
    const startDate = new Date(fee.starting_date);
    const current_installment = fee.frequency_type == 'monthly'
    ?(currentDate.getFullYear() - startDate.getFullYear()) * 12 + (currentDate.getMonth() - startDate.getMonth()) + (currentDate.getDate() > 5 ? 1:0)
    :fee.total_installments;
    const paid_fee = fee.fees_payment?.reduce((acc,pmnt)=>acc+parseFloat(pmnt.paid_amt)+parseFloat(pmnt.discount),0);
    return (fee.imposed_amount * parseInt(current_installment)) - paid_fee;
}
import React, { useEffect, useMemo, useState } from 'react'
import BreadCrumb from "../../../components/common/BreadCrumb"
import { Button, Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap'
import { TableResponsive } from '../../../components/common/TableResponsive'
import {examination, invoice} from '../../../helper/api_url'
import { Navigate, useNavigate } from 'react-router-dom';
import { UpdateQuestionModal } from './Modals/QuestionModals/UpdateQuestionModal';
import { ViewUserModal } from '../AllUser/ViewUserModal';
import { ViewQuestionModal } from './Modals/QuestionModals/ViewQuestionModal';
import Swal from "sweetalert2";
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { AddQuestionModal } from './Modals/QuestionModals/AddQuestionModal';
import { ExamModal } from './Modals/ExamModal'

function QuestionBank() {
    // const [data,setData] = useState([])
    const dispatch = useDispatch();
    // const {tableData,setTableData,tableLoading,apiHandler} = usePageInitialtor(examination);
    const [tableData,setTableData] = useState([]);
    const [tableLoading,setTableLoading] = useState(true);
    const [showModal,setShow]=useState(false)
    const [showExam,setShowExam] = useState(true)
    const navigate = useNavigate()


    const handleDelete = (data) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete: " + data.course_name,
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: "Delete",
            denyButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
                setTableLoading(true);
                dispatch(setPreloader({ loader: true, message: 'Deleting, please wait...' }));
    
                // Assuming examination.deleteQuestion returns a promise
                examination.deleteQuestion(data.id)
                    .then(res => {
                        // Update table data after successful deletion
                      
                        setTableData(tableData.filter(td => td.id !== data.id));
                        
                        Swal.fire('Deleted!', res.message, 'success');
                    })
                    .catch(err => {
                        Swal.fire('Error!', err.response ? err.response.data.message : err.message, 'error');
                    })
                    .finally(() => {
                        dispatch(setPreloader({ loader: false, message: '' }));
                        setTableLoading(false);
                    });
            }
        });
    };

    useEffect( ()=>{

        fetchCourses();
    },[])
    

    const fetchCourses = async () => {
        try {
            const response = await examination.readQuestions();
            console.log('response',response.data.question)
            setTableData(response.data.question);
        } catch (error) {
            console.error('Error fetching questions:', error);
        }
    }




        
    const columns = useMemo(()=>[
        {
            Header: "Course name",
            accessor: "course.name",
            HeaderClass:'',
            DataClass:'',
        },
        {
            Header: "Subject",
            accessor: "subject",
            HeaderClass:'',
            DataClass:'',
        },
        {
            Header: "Question",
            accessor: "question",
            HeaderClass:'',
            DataClass:'',
        },
        {
            Header: "Created Name",
            accessor: "creator_name",
            HeaderClass:'',
            DataClass:'',
        },
        {
            Header: "Action",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell: (cell) => {
              return ( 
                <div className='widthFix'>
                  
                    <ViewQuestionModal 
                    data={cell.row.original}
                      />
                    <UpdateQuestionModal
                    data={cell.row.original} 
                    setTableData={setTableData}
                     />
               
                     
                    <Button 
                    onClick={()=> handleDelete(cell.row.original)} 
                        className="btn btn-sm btn-soft-danger me-1" >
                        <i className="ri-delete-bin-fill" />  
                    </Button>
                </div>
              )
            },
        }
    ]);

const handleShow=()=>{
    console.log('open modal')
    setShow(true)
}

    return (
        <>
            <BreadCrumb title="Question Bank" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Question Bank</h5>
                            <AddQuestionModal fetchCourses={fetchCourses} setTableData={setTableData} data={tableData} setShow={setShow} show={showModal} />
                            {/* <ExamModal fetchCourses={fetchCourses} setTableData={setTableData} data={tableData} setShowExam={setShowExam} show={showExam} /> */}
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive isLoading={true} columns={columns} data={tableData} noRecord="No Record Found" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default QuestionBank
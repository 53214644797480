import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { ASSET_URL, student } from '../../helper/api_url';
import Error404 from '../Error/404';
import { Accordion, Col, Row } from 'react-bootstrap';
import { formatDate } from '../../helper/formatDate';
import { CourseDetail } from './courseDetail';
import { Document } from './Document';
import image_helper from '../../helper/image_helper';
import { PermanentAddress } from '../admin/Admission/permanentAddress';
import { Qualification } from '../admin/Admission/Qualification';
import { EnrollementCourse } from '../admin/Admission/EnrollementCourse';
import { ViewBatchModal } from '../admin/Batch/ViewBatchModal';
function Profile({ data }) {
    const { state } = useLocation();
    const profileData = state && state.data;
    const [userData, setUserData] = useState([]);
    useEffect(() => {
        student.profile(profileData?.id).then(r => setUserData(r.data.profile)).catch(e => console.log(e));
    }, []);
    return (
        <>
            {profileData ? (
                <div>
                    <div className="profile-foreground position-relative mx-n4 mt-n4">
                        <div className="profile-wid-bg" >
                            <img src={image_helper.profilebg} alt="" className="profile-wid-img" />
                        </div>
                    </div>
                    <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
                        <div className="row g-4">
                            <div className="col-auto">
                                <div className="avatar-lg">
                                    <img src={userData.avatar ? ASSET_URL + userData?.avatar : "assets/images/users/avatar-1.jpg"} alt="user-img" style={{ aspectRatio: '1/1', objectFit: 'cover' }} className="img-thumbnail rounded-circle" />
                                </div>
                            </div>
                            <div className="col">
                                <div className="p-2">
                                    <h3 className="text-white mb-1">{userData?.first_name + ' ' + userData?.last_name ?? 'unknow'}
                                        <ViewBatchModal batch={userData?.batches ?? null} /></h3>
                                    <p className="text-success">{userData?.role?.name ?? 'vistior'}</p>
                                    <div className="hstack text-white-50 gap-1">
                                        <div className="me-2"><i className="bx bx-phone-call me-1 text-white-75 fs-16 align-middle"></i>{profileData.phone ? profileData.phone : '999999999'}</div>
                                        <div>
                                            <i className="bx bx-envelope me-1 text-white-75 fs-16 align-middle"></i>{profileData.email ? profileData.email : '-'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-auto order-last order-lg-0">
                                <div className="row text text-white-50 text-center">
                                    <div className="col-lg-6 col-4">
                                        <div className="p-2">
                                            <h4 className="text-white mb-1">50%</h4>
                                            <p className="fs-14 mb-0">Present</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-4">
                                        <div className="p-2">
                                            <h4 className="text-white mb-1">5%</h4>
                                            <p className="fs-14 mb-0">Absent</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div>
                                <div className="d-flex">
                                    <ul className="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                                        <li className="nav-item">
                                            <Link to="/admission" className="nav-link fs-14 bg-light text-dark" > <i className="ri-arrow-left-line d-inline-block "></i></Link>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link fs-14 active" data-bs-toggle="tab" href="#overview-tab" role="tab">
                                                <i className="ri-airplay-fill d-inline-block d-md-none"></i> <span className="d-none d-md-inline-block">Overview</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link fs-14" data-bs-toggle="tab" href="#projects" role="tab">
                                                <i className="ri-price-tag-line d-inline-block d-md-none"></i> <span className="d-none d-md-inline-block">Class</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link fs-14" data-bs-toggle="tab" href="#documents" role="tab">
                                                <i className="ri-folder-4-line d-inline-block d-md-none"></i> <span className="d-none d-md-inline-block">Documents</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-content pt-4 text-muted">
                                    <div className="tab-pane active" id="overview-tab" role="tabpanel">
                                        <div className="row">
                                            <div className="col-xxl-4">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="card-title mb-3">
                                                            <Row>
                                                                <Col xs={12} lg={6} className='text-start'>Personal Infomation</Col>
                                                                <Col xs={12} lg={6} className='text-end'></Col>
                                                            </Row>
                                                        </div>
                                                        <ul className="list-group">
                                                            <li className='mb-3'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>Candidate Name </span></Col>
                                                                    <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='text-wrap'>{userData ? userData?.first_name : '-'} </span><span className='badge badge-soft-success px-1 ms-1'>{userData ? userData?.role?.name : ''}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='mb-3'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>Gender </span></Col>
                                                                    <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={6}>
                                                                        <span className='text-wrap'>{userData ? userData?.gender : '-'}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='mb-3'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>Date of Birth </span></Col>
                                                                    <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={6}>
                                                                        <span className='text-wrap'>{userData ? formatDate(userData?.dob) : '-'}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='mb-3'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>Class  </span></Col>
                                                                    <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={5}>
                                                                        <span className='text-wrap'>{userData && userData.courses && userData.courses.length > 0 ? userData.courses.map((item, idx) => (<span className='badge badge-soft-info px-2 ms-1 mx-2' key={idx}>{item.code}</span>)) : "-"}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='mb-3'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>Email </span></Col>
                                                                    <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={6}>
                                                                        <span className='text-wrap'>{userData ? userData?.email : '-'}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='mb-3'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>Phone </span></Col>
                                                                    <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={6}>
                                                                        <span className='text-wrap'>{userData ? userData?.phone : '-'}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='mb-3'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>whatsapp </span></Col>
                                                                    <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={6}>
                                                                        <span className='text-wrap'>-</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='mb-3'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>Addmission Date </span></Col>
                                                                    <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={6}>
                                                                        <span className='text-wrap'>{userData ? formatDate(userData?.personal_details?.created_at) : '-'}</span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                            <li className='mb-3'>
                                                                <Row>
                                                                    <Col xs={5}><span className='fw-bold'>Addmission By </span></Col>
                                                                    <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                    <Col className='text-start' xs={6}>
                                                                        <span className='text-wrap'><span className='badge badge-soft-primary px-2 ms-1'>{userData ? userData?.personal_details?.created_by : ''}</span></span>
                                                                    </Col>
                                                                </Row>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xxl-4">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="card-title mb-3">
                                                            <Row>
                                                                <Col xs={12} lg={6} className='text-start'>Address </Col>
                                                                <Col xs={12} lg={6} className='text-end'>
                                                                    {/* {userData.personal_details?.permanent_address?.type === "permanent" ? '' : <PermanentAddress data={profileData} setUserData={setUserData} />} */}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="">
                                                            <ul className="list-group">
                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Address</span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>
                                                                                {userData?.addresses?.[0]?.addr1 ?? '-'}
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>

                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Street</span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>
                                                                                {userData?.addresses?.[0]?.addr2 ?? '-'}
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>City</span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>
                                                                                {userData?.addresses?.[0]?.city ?? '-'}
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>


                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>District</span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>
                                                                                {userData?.addresses?.[0]?.district ?? '-'}
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>

                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>State </span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>
                                                                                {userData?.addresses?.[0]?.state ?? '-'}
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>

                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Pin Code </span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>
                                                                                {userData?.addresses?.[0]?.pin ?? '-'}
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Country </span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>India  </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xxl-4'>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="card-title mb-3">
                                                            <Row>
                                                                <Col xs={12} lg={6} className='text-start'>Qualification</Col>
                                                                <Col xs={12} lg={6} className='text-end'>
                                                                    <Qualification data={profileData} />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="">
                                                            <ul className="list-group">
                                                                <Accordion defaultActiveKey={0} flush>
                                                                    {userData && userData.qualifications && userData.qualifications.length > 0 ? userData.qualifications.map((item, idx) => (
                                                                        <Accordion.Item eventKey={idx} key={idx}>
                                                                            <Accordion.Header>{(item.name).toUpperCase()}</Accordion.Header>
                                                                            <Accordion.Body>
                                                                                <li className='mb-3'>
                                                                                    <Row>
                                                                                        <Col xs={5}><span className='fw-bold'>Board Name</span></Col>
                                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{item.board_university}</span></Col>
                                                                                    </Row>
                                                                                </li>
                                                                                <li className='mb-3'>
                                                                                    <Row>
                                                                                        <Col xs={5}><span className='fw-bold'>Passing Year</span></Col>
                                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{item.year}</span></Col>
                                                                                    </Row>
                                                                                </li>
                                                                                <li className='mb-3'>
                                                                                    <Row>
                                                                                        <Col xs={5}><span className='fw-bold'>Percentage</span></Col>
                                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{item.marks} %</span></Col>
                                                                                    </Row>
                                                                                </li>
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                    )) : "-"}
                                                                </Accordion>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xxl-4">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="card-title mb-3">
                                                            <Row>
                                                                <Col xs={12} lg={6} className='text-start'>Other Details</Col>
                                                                <Col xs={12} lg={6} className='text-end'>
                                                                    {/* <button className="btn btn-sm btn-success btn-end"><i className="ri-edit-box-line align-bottom"></i> Edit Profile</button> */}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="">
                                                            <ul className="list-group">
                                                                <h3>Father Information</h3>
                                                                <hr className='mt-0' />
                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col lg={6} className='text-center mx-auto' xs={5}>
                                                                            <div className="avatar-lg">
                                                                                <img src={userData.personal_details?.family?.father_photo ? ASSET_URL + userData.personal_details?.family?.father_photo : "assets/images/users/avatar-1.jpg"} alt="user-img" style={{ aspectRatio: '1/1', objectFit: 'cover' }} className="img-thumbnail rounded-circle" />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Name </span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>{userData.personal_details?.family?.father_name ?? '-'} </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Contact </span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>{userData.personal_details?.family?.father_mobile ?? '-'} </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Qualification </span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>{userData.personal_details?.family?.father_qualification ?? '-'} </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Occupation</span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>{userData.personal_details?.family?.father_occupation ?? '-'} </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Aadhaar</span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>{userData.personal_details?.family?.father_aadhaar ?? '-'} </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                                <br />
                                                                <h3>Mother Information</h3>
                                                                <hr className='mt-0' />
                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col lg={6} className='text-center mx-auto' xs={5}>
                                                                            <div className="avatar-lg">
                                                                                <img src={userData.personal_details?.family?.mother_photo ? ASSET_URL + userData.personal_details?.family?.mother_photo : "assets/images/users/avatar-1.jpg"} alt="user-img" style={{ aspectRatio: '1/1', objectFit: 'cover' }} className="img-thumbnail rounded-circle" />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Mother Name </span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>{userData.personal_details?.family?.mother_name ?? '-'}  </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>


                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Contact </span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>{userData.personal_details?.mother_mobile ?? '-'}  </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>

                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Qualification </span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>{userData.personal_details?.mother_qualification ?? '-'}  </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Occupation </span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>{userData.personal_details?.mother_occupation ?? '-'}  </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Aadhaar </span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>{userData.personal_details?.mother_aadhaar ?? '-'}  </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            {/* <div className='col-xxl-4'>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="card-title mb-3">
                                                            <Row>
                                                                <Col xs={12} lg={6} className='text-start'>Current Address </Col>
                                                                <Col xs={12} lg={6} className='text-end'>
                                                                    {userData.personal_details?.current_address?.type === "current" ? '' : <PermanentAddress data={profileData} setUserData={setUserData} />}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="">
                                                            <ul className="list-group">
                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Address</span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>{userData.personal_details?.current_address?.addr1 ?? '-'} </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>

                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Street</span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>{userData.personal_details?.current_address?.addr2 ?? '-'} </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>

                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>City</span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>{userData.personal_details?.current_address?.city ?? '-'} </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>

                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>District</span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>{userData.personal_details?.current_address?.district ?? '-'}</span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>



                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>State </span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>{userData.personal_details?.current_address?.state ?? '-'}  </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>

                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Pin Code </span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>{userData.personal_details?.current_address?.pin ?? '-'}  </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                                <li className='mb-3'>
                                                                    <Row>
                                                                        <Col xs={5}><span className='fw-bold'>Country </span></Col>
                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                        <Col className='text-start' xs={5}>
                                                                            <span className='text-wrap'>India  </span>
                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className='col-xxl-4'>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="card-title mb-3">
                                                            <Row>
                                                                <Col xs={12} lg={6} className='text-start'>Class</Col>
                                                                <Col xs={12} lg={6} className='text-end'>
                                                                    <EnrollementCourse data={profileData} />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="">
                                                            <ul className="list-group">
                                                                <Accordion defaultActiveKey={0} flush>
                                                                    {userData && userData.courses && userData.courses.length > 0 ? userData.courses.map((item, idx) => (
                                                                        <Accordion.Item eventKey={idx} key={idx}>
                                                                            <Accordion.Header>{(item.code).toUpperCase()}</Accordion.Header>
                                                                            <Accordion.Body>
                                                                                <li className='mb-3'>
                                                                                    <Row>
                                                                                        <Col xs={5}><span className='fw-bold'>Class Name</span></Col>
                                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{item.name}</span></Col>
                                                                                    </Row>
                                                                                </li>
                                                                                <li className='mb-3'>
                                                                                    <Row>
                                                                                        <Col xs={5}><span className='fw-bold'>Type</span></Col>
                                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{item.type}</span></Col>
                                                                                    </Row>
                                                                                </li>
                                                                                <li className='mb-3'>
                                                                                    <Row>
                                                                                        <Col xs={5}><span className='fw-bold'>Duration</span></Col>
                                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{item.semesters} Semester</span></Col>
                                                                                    </Row>
                                                                                </li>
                                                                                <li className='mb-3'>
                                                                                    <Row>
                                                                                        <Col xs={5}><span className='fw-bold'>Eligibility</span></Col>
                                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{(item.min_eligibility).toUpperCase()} </span></Col>
                                                                                    </Row>
                                                                                </li>
                                                                                <li className='mb-3'>
                                                                                    <Row>
                                                                                        <Col xs={5}><span className='fw-bold'>Description</span></Col>
                                                                                        <Col xs={1}><span className='fw-bold'>:</span></Col>
                                                                                        <Col className='text-start' xs={5}><span className='text-wrap'>{item.description} </span></Col>
                                                                                    </Row>
                                                                                </li>
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                    )) : "-"}
                                                                </Accordion>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='col-xxl-4'>
                                         <div className="card">
                                                 <div className="card-body">
                                                     <h5 className="card-title mb-3">Director Desk</h5>
                                                     <p>
                                                     एस ० के ० इंस्टिट्यूट आफ कंप्यूटर साइंस एंड टेक्नोलॉजी परिवार में आपका स्वागत है 
                                                     आप ऐसे परिवार में सम्मिलित हो रहे है जहाँ का उद्देश्य स्पष्ट और इरादे मजबूत है 
                                                     हम अक्सर परम्परागत शिक्षा को चुनते है और उस पर अधिक ध्यान देते है | परम्परागत शिक्षा हमें केवल परीक्षाओ को पास करने में सहायक होती है किन्तु यदि हम अपने कौशल और परिश्रम से कुछ कर दिखाना चाहते है तो तकनीक शिक्षा को प्राप्त करना आवश्यक है हमारा प्रयास है की हर छात्र / छात्रा को इस प्रकार प्रशिक्षित किया जाए की वह अपने कौशल  के बल पर अपने पैरो पर खड़ा हो सके और देश के किसी भी कोने में रहकर अपने कौशल के दम पर अपना और अपने परिवरिश का परण-पोषण कर सके | 
                                                     हमारा लक्ष्य है की हमसे जुड़े प्रत्येक छात्र/छात्रा आत्मनिर्भर बने | 
                                                     </p>
                                                     <p>
                                                     कंप्यूटर शिक्षा या तकनीकी शिक्षा छात्र /छात्रा  में एक आत्मविश्वास जागता है और स्वयं के कौशल के दम पर कुछ करने की प्रेरणा देता है 
                                                     वर्त्तमान समय में सरकारी नौकरियो में भी कौशल (skill )और प्रबंधन (Management ) को प्राथमिकता दी जा रही है | 
                                                     नित नये प्रयोगो से हमने प्रशिक्षण को नई दिशा दी है| Computer & Technical Education , Spoken English , Computer Typing हमारे कुछ प्रशिक्षक के  सही मार्गदर्शन और कठिन परिश्रम से सब कुछ सरल और सुगम तरीके से सिखाया जा रहा है | 
                                                     एस ० के ० इंस्टिट्यूट आफ कंप्यूटर साइंस एंड टेक्नोलॉजी परिवार का हिस्सा बनकर अपने अपने सफलता के प्रथम सीढ़ी पर कदम रखा है | हमें पूर्ण विश्वास है की आप इंस्टिट्यूट में नियमित उपथित होकर, अपने प्रशिक्षक के निर्देशों का पालन करके और कठिन परिश्रम से अपने माता-पिता तथा परिवार जनो के सपनो को साकार करेंगे | दिया गया उत्कृत्ष्ट मार्गदर्शन और आपका कठिन प्रयास आपको अपना लक्ष्य प्राप्त करने में सहायक हो ऐसी ईश्वर से प्राथना है | 
                                                     </p>
                                                     <div className="row">
                                                         <div className="col-6 col-md-4">
                                                             <div className="d-flex mt-4">
                                                                 <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                     <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                         <i className="ri-user-2-fill"></i>
                                                                     </div>
                                                                 </div>
                                                                 <div className="flex-grow-1 overflow-hidden">
                                                                     <p className="mb-1">Addmission By :</p>
                                                                     <h6 className="text-truncate mb-0">-</h6>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                         <div className="col-6 col-md-4">
                                                             <div className="d-flex mt-4">
                                                                 <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                     <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                         <i className="ri-global-line"></i>
                                                                     </div>
                                                                 </div>
                                                                 <div className="flex-grow-1 overflow-hidden">
                                                                     <p className="mb-1">Email :</p>
                                                                     <a href="#" className="fw-semibold">-</a>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
                                         </div> */}
                                        </div>
                                    </div>

                                    <CourseDetail courses={userData?.courses} />

                                    <Document documents={userData?.documents} />

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            ) : <Error404 />

            }


        </>
    )

}

export default Profile
import React, { useEffect, useMemo, useState } from 'react';
import { Card, Modal, Row, Col, Button } from 'react-bootstrap';
import { useModalHandler } from '../../../helper/custom_hook';
import { swal } from '../../../helper/swal';
import { examination } from '../../../helper/api_url';
import CustomSelect from '../../../components/CustomSelect';
import { TableResponsive } from '../../../components/common/TableResponsive';

export function FetchQuestion({ setAllQuestion }) {
    const { toggleModal, status } = useModalHandler();
    const [tableData, setTableData] = useState([]);
    const [showQuestion, setShowQuestion] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState([]);

    useEffect(() => {
        examination.readQuestions()
            .then(res => setTableData(res.data.question))
            .catch(err => swal.error(err.response?.data?.message || err.message));
    }, []);

    const handleQuestionChange = e => {
        const question = tableData.filter(item => item.subject === e.value);
        setShowQuestion(question);
    };

    const handleSelectQuestion = (questionId) => {
        setSelectedQuestions(prevSelected => {
            if (prevSelected.includes(questionId)) {
                return prevSelected.filter(id => id !== questionId);
            } else {
                return [...prevSelected, questionId];
            }
        });
    };

    const handleConfirmQuestions = () => {
        const selectedQuestionData = showQuestion.filter(q => selectedQuestions.includes(q.id));
        setAllQuestion(state => [...state, ...selectedQuestionData.map(q => ({ ...q, marks: 0 }))]);
        toggleModal();
    };

    const columns = useMemo(() => [
        {
            Header: "Select",
            accessor: "id",
            Cell: cell => (
                <input
                    type="checkbox"
                    checked={selectedQuestions.includes(cell.row.original.id)}
                    onChange={() => handleSelectQuestion(cell.row.original.id)}
                />
            ),
            HeaderClass: 'text-center',
            DataClass: 'text-center'
        },
        {
            Header: "Question",
            accessor: "question",
            HeaderClass: 'text-center',
            DataClass: ''
        },
        {
            Header: "Action",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: () => (
                <Button className="btn btn-sm btn-soft-danger me-1">
                    <i className="ri-delete-bin-fill" />
                </Button>
            )
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                const bs_colors = ['primary', 'info', 'warning', 'danger', 'success', 'light', 'secondary'];
                const badge_class = row.id <= 6 ? `badge-outline-${bs_colors[row.id]}` : `badge-S-${bs_colors[row.id % 6]}`;
                return (
                    <div className="d-flex">
                        <div className="flex-grow-1" data-id="1">
                            <h5 className="fs-13 mb-1">
                                <input type="checkbox" checked={selectedQuestions.includes(row.id)} id={`quest_${row.id}`} onChange={() => handleSelectQuestion(row.id)} />
                            </h5>
                            <label htmlFor={`quest_${row.id}`}><p className="text-muted mb-0">{row.question} </p></label>
                            
                        </div>
                        <div className="flex-shrink-0">
                            <div>
                                {/* <Button className="btn btn-sm btn-soft-danger me-1">
                                    <i className="ri-delete-bin-fill" />
                                </Button> */}
                            </div>
                        </div>
                    </div>
                )
            }
        }
    ], [selectedQuestions]);

    return (
        <>
            <button onClick={(e) => { e.preventDefault(); toggleModal() }} className='btn btn-soft-success add-btn waves-effect'>
                <i className="ri-add-line align-bottom me-1"></i>
                <span>Add Question</span>
            </button>

            <Modal centered backdrop="static" show={status} onHide={toggleModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Choose Question</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className='mx-3'>
                        <Card.Body>
                            <Row>
                                <Col xs={12}>
                                    <label htmlFor="subject" className="form-label">Select Subject</label>
                                    <CustomSelect
                                        id="subject"
                                        options={tableData.map(item => ({ label: item.subject, value: item.subject }))}
                                        onChange={handleQuestionChange}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Body>
                            <TableResponsive
                                columns={columns}
                                data={showQuestion}
                                isShowingPageLength={false}
                                isPagination={false}
                                showCustomOptionPage={false}
                                noRecord="No Record Course List"
                            />
                            <div className='text-center mt-3'>
                                <Button className="btn btn-sm btn-soft-primary" onClick={handleConfirmQuestions}>
                                    Confirm Questions
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    );
}

import React, { useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { course} from '../../../helper/api_url'
import { Tooltip } from 'antd';
import { TextEditor } from '../../../components/common/TextEditor';
import { useModalHandler } from '../../../helper/custom_hook';
import CustomSelect from '../../../components/CustomSelect';
export function NewCourseModal({ apiHandler }) {

    const {toggleModal,status} = useModalHandler();
    const courseType=[
        {value:'pre-primary',label:'Pre Primary'},
        {value:'primary',label:'Primary'},
        {value:'Secondary',label:'Secondary'},
        {value:'senior-secondary',label:'Senior Secondary'},
        {value:'other',label:'Other'},
    ];
    
    return (
        <>
            <button onClick={toggleModal} className='btn btn-soft-success add-btn waves-effect'>
                <i className="ri-add-line align-bottom me-1"></i>
                <span>Add Class</span>
            </button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>New Class</h5></Modal.Title>
                </Modal.Header>
                    <form onSubmit={e => apiHandler.handleSubmit(e,course.add,toggleModal)}>
                <Modal.Body>
                        <div className="row g-3">
                            <div className="col-8">
                                <div>
                                    <label htmlFor="enquiryName" className="form-label">Name</label>
                                    <input type="text" className="form-control" id='enquiryName' name="name" placeholder="" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div>
                                    <label htmlFor="code" className="form-label">Code</label>
                                    <Tooltip  placement="top" title="Unique Code" ><i className='text-danger ps-1 align-center bx bx-help-circle' /></Tooltip>
                                    <input type="text" className="form-control" id='code' name="code" placeholder="" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="section" className="form-label">Section</label>
                                    <CustomSelect options={[
                                        {label:'A',value:'a'},
                                        {label:'B',value:'b'},
                                        {label:'C',value:'c'},
                                        {label:'D',value:'d'},
                                        {label:'E',value:'e'},
                                    ]} name='section'  id='section' />
                                </div>
                            </div>                           
                            <div className="col-6">
                                <div>
                                    <label htmlFor="type" className="form-label">Type</label>
                                    <CustomSelect options={courseType} name='type'  id='type' />
                                </div>
                            </div>                           
                            <input type='hidden' name='semesters' value="12" />                            
                            <input type='hidden' name='sem_duration' value="1" />                            
                            <input type='hidden' name='min_eligibility' value="none" />                            
                        </div>
                </Modal.Body>
                <Modal.Footer>
                            <div className="col-lg-12">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                            </Modal.Footer>
                    </form>
            </Modal>
        </>
    )
}
import React, { useEffect,useState, useMemo} from 'react'
import BreadCrumb from "../../../components/common/BreadCrumb";
import { TableResponsive } from "../../../components/common/TableResponsive";
import { Card, CardBody, CardHeader, Col, Row, Button } from "react-bootstrap";
import { ViewUserModal } from './ViewUserModal';
import { AllUserModal} from './AllUserModal';
import { UpdateAllUserModal} from './UpdateAllUserModal';
import { allUser,ASSET_URL } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { Switch } from 'antd';
import { usePageInitialtor } from '../../../helper/custom_hook';

function AllUsers() {
    const {tableData,setTableData,tableLoading,apiHandler} = usePageInitialtor(allUser);
    const {handleDelete} = apiHandler;
    const handleUserStateChange = function(user){
        setTableData([...tableData.map(u => (u.id === user.id ? { ...u, changing: true } : u))]);
        allUser.changeStatus(user.id).then(res=>{ 
            setTableData([...tableData.map(u => (u.id === user.id ? { ...u, is_active: res.data.user.is_active } : u))]);
        }).catch(err=>swal.error(err.response?err.response.data.message:err.message));
    }
    const columns = useMemo(()=>[
        {
            Header: "Name",
            accessor: "first_name",
            HeaderClass:'',
            DataClass:'',
            Cell:(cell)=> {
                const imageUrl =ASSET_URL + cell.row.original.avatar;
                return (<span> <img className="me-2 rounded-circle header-profile-user" style={{objectFit:'cover'}} src={imageUrl} alt="User Avatar" />{cell.row.original.first_name} {cell.row.original.last_name}</span>)
            }
        },
        {
            Header: "Phone",
            accessor: "phone",
            HeaderClass:'',
            DataClass:'',
        },
        {
            Header: "Email",
            accessor: "email",
            HeaderClass:'',
            DataClass:'',
        },
        {
            Header: "User Type",
            accessor: "role.name",
            HeaderClass:'text-center',
            DataClass:'text-center',
        },
        {
            Header: "Status",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell:(cell) => {
                return (<Switch 
                    className={cell.row.original.is_active ?'bg-primary':''}
                    loading={cell.row.original.changing?true:false}
                    checkedChildren={(<span style={{fontSize:"10px"}}>Active</span>)} 
                    unCheckedChildren={(<span style={{fontSize:"10px"}}>Deactive</span>)} 
                    checked={cell.row.original.is_active} 
                    onChange={()=>handleUserStateChange(cell.row.original)}
                />)
            }
        },
        {
            Header: "Action",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell: (cell) => {
              return ( 
                <div className="">
                  
                    <ViewUserModal data={cell.row.original}  />
                    <UpdateAllUserModal data={cell.row.original} apiHandler={apiHandler} />
                    <Button onClick={()=>handleUserDelete(cell.row.original.id,cell.row.original.first_name)} className="btn btn-sm btn-soft-danger me-1" >
                        <i className="ri-delete-bin-fill" />  
                    </Button>
                </div>
              )
            },
          },
          {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                const imageUrl = ASSET_URL+row.avatar;
                return (
                <div className="d-flex">
                   <ViewUserModal data={row}> 
                   <img className="me-2 rounded-circle header-profile-user" src={imageUrl} alt="User Avatar" />
                   </ViewUserModal>
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                            <a href="#" className="link text-dark"></a>
                            <a href="#">{row.first_name} {row.last_name}</a>
                        </h5>
                        <p className="text-muted mb-0"><a href={`tel:${row.phone}`}><i className='bx bx-phone text-success' />{row.phone}</a> | <i className='bx bx-mail-send' />{row.email}</p>
                    </div>
                    <div className="flex-shrink-0">
                        <div>
                            <Switch 
                                className={row.is_active ?'bg-primary me-2':'me-2'}
                                loading={row.changing?true:false}
                                checked={row.is_active} 
                                onChange={()=>handleUserStateChange(row)}
                                checkedChildren={(<span style={{fontSize:"10px"}}>Active</span>)} 
                                unCheckedChildren={(<span style={{fontSize:"10px"}}>Deactive</span>)} 
                            />
                            <UpdateAllUserModal data={row}  apiHandler={apiHandler} />
                            <button onClick={()=>handleUserDelete(row.id,row.first_name)} className="btn btn-sm btn-soft-danger me-1" data-id="1"> <i className="ri-delete-bin-fill"></i> </button>
                        </div>
                    </div>
                </div>
                )
            }
          }
    ]);
    const handleUserDelete = (userId,name) =>handleDelete({name:name,id:userId});
    return (
    <>
        <BreadCrumb title="Users" prevPage="Home" prevPath="/dashboard" />
        <Row>
            <Col xs={12}>
                <Card>
                    <CardHeader className="d-flex align-items-center justify-content-between">
                        <h5 className="card-title mb-0">All User's List</h5>
                        <AllUserModal apiHandler={apiHandler} />
                    </CardHeader>
                    <CardBody className="">
                        <TableResponsive isLoading={tableLoading} columns={columns} data={tableData?tableData:[]} noRecord="No Record User List"  />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
    )
}
export default AllUsers
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Card, CardHeader, CardBody, Container, Modal, Form, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { swal } from '../../../helper/swal';
import Camera from '../../../components/common/Camera';
import { setPreloader } from "../../../features/Ui/uiSlice";
import BreadCrumb from '../../../components/common/BreadCrumb'
import { useModalHandler, useWebCam } from '../../../helper/custom_hook';
import { ASSET_URL, student, enquiry, fee, family, userAddress, course } from '../../../helper/api_url';
import CustomSelect from "../../../components/CustomSelect";
import dummyUser from "../../../assets/images/users/user-dummy-img.jpg"
import { TableResponsive } from "../../../components/common/TableResponsive";



function Admission() {
  const dispatch = useDispatch();
  const { genders, status, toggleModal, userProfile, setUserProfile } = useModalHandler();
  const { webcam, toggleWebcam, setCapturedImageToInput } = useWebCam(setUserProfile);
  const [enquiryData, setEnquiryData] = useState([]);
  const [allFees,setAllFees] = useState([]);
  const [courses,setCourses] = useState([]);
  const [currentUser,setCurrentUser] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [families,setFamilies] = useState([]);
  const [familyModal, setFamilyModal]=useState(false);
  const onSave = f => { setCapturedImageToInput(f, document.getElementById('avatarInput')) }
  useEffect(()=>{
    if(activeStep == 2){
      family.getAll()
      .then(res=>setFamilies(res.data.family))
      .catch(e=>console.log(e));
    }
    if(activeStep == 4){
      fee.list().then(r=>setAllFees(r.data.fee)).catch(e=>console.error(e));
    }
  },[activeStep]);
  const handleFamilyFormSubmit = e => {
    dispatch(setPreloader({ loader: true, message: 'please wait...' }))
    e.preventDefault();
    const formData = new FormData(e.target);
    family.add(formData).then(res => {
      dispatch(setPreloader({ loader: false, message: '' }))
      swal.success(res.data.message);
      setFamilies(state=>[res.data.family,...state]);
      setFamilyModal(!familyModal);
    }).catch(err => {
      dispatch(setPreloader({ loader: false, message: '' }))
      swal.error(err.response ? err.response.data.message : err.message)
    })
  }
  const handleUserCreationSubmit = e => {
    e.preventDefault();
    // setActiveStep(2);
    // return
    dispatch(setPreloader({ loader: true, message: 'please wait...' }))
    const formData = new FormData(e.target);
    student.add(formData).then(res => {
      dispatch(setPreloader({ loader: false, message: '' }))
      swal.success(res.data.message);
      setActiveStep(2);
      setCurrentUser(res.data.student);
    }).catch(err => {
      dispatch(setPreloader({ loader: false, message: '' }))
      swal.error(err.response ? err.response.data.message : err.message)
    })
  }
  const handleOtherDetailsSubmit = e =>{
    dispatch(setPreloader({ loader: true, message: 'please wait...' }))
    e.preventDefault();
    const formData = new FormData(e.target);
    student.otherDetails(formData).then(res => {
      dispatch(setPreloader({ loader: false, message: '' }))
      swal.success(res.data.message);
      setActiveStep(3);
    }).catch(err => {
      dispatch(setPreloader({ loader: false, message: '' }))
      swal.error(err.response ? err.response.data.message : err.message)
    })
  }
  const handleAddressFormSubmit = e => {
    dispatch(setPreloader({ loader: true, message: 'please wait...' }))
    e.preventDefault();
    const formData = new FormData(e.target);
    userAddress.add(formData).then(res => {
      swal.success(res.data.message);
      setActiveStep(4);
    }).catch(err =>swal.error(err.response ? err.response.data.message : err.message))
    .finally(()=>dispatch(setPreloader({ loader: false, message: '' })))
  }


  
  const [selectedCourseFees, setSelectedCourseFees] = useState([]);
  const [findEnquiry, setFindEnquiry] = useState([]);
  useEffect(() => {
    course.list().then(r=>setCourses(r.data.course)).catch(e=>console.error(e));
    if(status)
    enquiry.list()
    .then(r => setFindEnquiry(r.data.enquiry.map((enquiry) => ({ 
      label: enquiry.name + ' (' + enquiry.father_name + ') ' + enquiry.phone, 
      value: enquiry.id, })))).catch(e => console.log(e));
  }, [status])
  const fetchEnquiryData = (e) => {
    e.preventDefault();
    const formdata = new FormData(e.target);
    enquiry.fetchSingle(formdata.get('enquiry_id') || '0').then(r => { setEnquiryData(r.data.enquiry); setUserProfile(ASSET_URL + r.data.enquiry.avatar); toggleModal(); }).catch(err => { swal.error(err.response ? err.response.data.message : err.message); })
  }

  const fees_table_columns = useMemo(()=>[
    {
      Header: "Fees Name",
      accessor: "name",
    },
    {
      Header: "Fees Type",
      accessor: "type",
      HeaderClass:'text-center',
      DataClass:'text-center',
    },
    {
      Header: "Amount",
      accessor: "amount",
      HeaderClass:'text-center',
      DataClass:'text-center',
    },
    {
      Header: "Final Amount",
      HeaderClass:'text-center',
      DataClass:'text-center',
      Cell:cell=>(<div className="d-flex align-items-center justify-content-center">
        <input className="form-control" style={{maxWidth:"100px"}} defaultValue={cell.row.original.amount} name={"fees_final_"+cell.row.original.id} />
      </div>)
    },
    {
      Header: "Action",
      Cell:cell=>(<button type="button" onClick={()=>handleFeeDeletionFromSelectedCourseFee(cell.row.original.id)} className="btn btn-sm btn-soft-danger">
        <i className="ri-delete-bin-fill"></i>
      </button>),
      HeaderClass:'text-center',
      DataClass:'text-center',
    },
    {
      Header:'List',
      HeaderClass:'d-none',
      DataClass:'d-none',
      list:(row)=>{
        return (
          <div className="d-flex">
              <div className="flex-grow-1" data-id="1">
                  <h5 className="fs-13 mb-1">
                      <a href="#" className="link text-dark"></a>
                      <a href="#">{row.name} {row.amount}</a>
                  </h5>
              </div>
          </div>
          )
      }
    }
  ])
  const handleEnrollmentSubmit = e => {
    e.preventDefault(); 
    dispatch(setPreloader({ loader: true, message: 'please wait...' }))
    const formData = new FormData(e.target);
    const enrollmentData = {
      user_id:formData.get('user_id'),
      fees:selectedCourseFees.map(fee=>({fee_id:fee.id,final_amount:e.target[`fees_final_${fee.id}`].value})),
      class_id:formData.get('class_id'),
      section:formData.get('section'),
    }
    student.enroll(enrollmentData).then(res =>swal.success(res.data.message))
    .catch(err=>swal.error(err.response ? err.response.data.message : err.message))
    .finally(()=> dispatch(setPreloader({ loader: false, message: '' })))
  }
  const handleFeeAdditionToSelectedCourseFee = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    if(formData.get('fee_id')){
      if(!selectedCourseFees.some(i=>i.id == formData.get('fee_id')))
      setSelectedCourseFees([...selectedCourseFees,...allFees.filter(f=>f.id == formData.get('fee_id'))])
      setAllFees(fees=>fees.filter(f=>f.id != formData.get('fee_id')))
      
    }
  }
  const handleFeeDeletionFromSelectedCourseFee = fee_id => {
    if(fee_id){
      if(!allFees.some(i=>i.id == fee_id))
      setAllFees([...allFees,...selectedCourseFees.filter(f=>f.id == fee_id)])
      setSelectedCourseFees(fees=>fees.filter(f=>f.id != fee_id));
    }
  }
  return (
    <>
      <BreadCrumb title="Addmission" prevPage="Home" prevPath="/dashboard" />
      <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title><h5>Fetch Enquiry</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <form onSubmit={(e) => fetchEnquiryData(e)}>
            <div className="row g-3">
              <div className="col-12">
                <div>
                  <label htmlFor="enquiry_id" className="form-label">Enquiry Id</label>
                  <CustomSelect name="enquiry_id" isSearchable id='enquiry_id' placeholder="Select Enquiry" options={findEnquiry} />
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-3">
              <div className="hstack gap-2 justify-content-end">
                <button type="submit" className="btn btn-primary">Next</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Row>
        <Col xs={12}>
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between">
              <h5 className="card-title mb-0">New Addmission </h5>
              <div>
                <Link to="/admission" className='btn btn-soft-dark add-btn waves-effect me-2'>
                  <i className="ri-contacts-fill"></i>
                </Link>
                <button onClick={() => toggleModal()} className='btn btn-soft-success add-btn waves-effect'>
                  <i className="ri-add-line align-bottom me-1"></i>
                  <span>Fetch Enquiry</span>
                </button>
              </div>
            </CardHeader>
            <CardBody>
              <Row className="g-3 mx-lg-5">
                <div id="custom-progress-bar" className="progress-nav mb-4">
                  <div className="progress" style={{ height: "1px" }}>
                    <div className="progress-bar" role="progressbar" style={{ width: "0%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <Nav variant="pills" className="">
                    <Nav.Item ><Nav.Link className={`rounded-pill d-flex justify-content-center align-items-center ${activeStep === 1 ? 'active' : ''}`} id="activeStep1">1</Nav.Link></Nav.Item>
                    <Nav.Item > <Nav.Link className={`rounded-pill d-flex justify-content-center align-items-center ${activeStep === 2 ? 'active' : ''}`} id="activeStep2">2</Nav.Link></Nav.Item>
                    <Nav.Item > <Nav.Link className={`rounded-pill d-flex justify-content-center align-items-center ${activeStep === 3 ? 'active' : ''}`} id="activeStep3">3</Nav.Link></Nav.Item>
                    <Nav.Item > <Nav.Link className={`rounded-pill d-flex justify-content-center align-items-center ${activeStep === 4 ? 'active' : ''}`} id="activeStep4">4</Nav.Link></Nav.Item>
                  </Nav>
                </div>
                <Tab.Container activeKey={activeStep}>
                  <Tab.Content>
                    <Tab.Pane eventKey={1}>
                      <div className="" id="">
                        <div className="mb-4">
                          <h5 className="mb-1 text-center text-primary">Student Details</h5>
                        </div>
                        <form onSubmit={e=>handleUserCreationSubmit(e)}>
                          <Row>
                            <Col className="text-center">
                              <div className="profile-user position-relative d-inline-block mx-auto mb-2">
                                <label htmlFor="profile-img-file-input" className="cursor-pointer"> <img src={userProfile ? userProfile : dummyUser} htmlFor="profile-img-file-input" className="rounded-circle avatar-lg img-thumbnail user-profile-image cursor" alt="" /></label>
                                <div className="avatar-xs ms-0 me-5 mb-2 rounded-circle profile-photo-edit" style={{ marginLeft: "31px" }}>
                                  <input id="profile-img-file-input" type="file" name="avatar" className="profile-img-file-input" accept="image/png, image/jpeg, image/jpg" onChange={(e) => onSave(e.target.files[0])} defaultValue="" />
                                </div>
                                <div className="avatar-xs rounded-circle profile-photo-edit">
                                  <div onClick={toggleWebcam} className="avatar-title rounded-circle bg-light text-body"><i className="ri-camera-fill"></i></div>
                                </div>
                              </div>
                              {webcam ? (<div className='position-relative top-0 bottom-0 d-flex justify-content-center align-items-center start-0 end-0 modal-content border-0' style={{ zIndex: 9 }}><Camera onSave={f => { onSave(f); toggleWebcam() }} onClose={() => toggleWebcam()} /></div>) : null}
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <div className="col-6 mt-2">
                              <input type="hidden" name="enquiry_avatar" defaultValue={enquiryData ? enquiryData.avatar : null} />
                              <input type="hidden" name="enquiry_id" defaultValue={enquiryData ? enquiryData.id : null} />
                              <div>
                                <label htmlFor="firstName" className="form-label">First Name</label>
                                <input type="text" className="form-control" id='firstName' name="first_name" defaultValue={enquiryData ? enquiryData.name : ''} />
                              </div>
                            </div>
                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="last_name" className="form-label">Last Name</label>
                                <input type="text" className="form-control" id='last_name' defaultValue="" name="last_name" />
                              </div>
                            </div>
                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="gender" className="form-label">Gender</label>
                                <CustomSelect name='gender' id="gender" defaultValue={{ value: enquiryData && enquiryData.gender, label: enquiryData && enquiryData.gender }} isSearchable options={genders} />
                              </div>
                            </div>
                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="dob" className="form-label">Date of Birth</label>
                                <input type="date" className="form-control" id='dob' name="dob" defaultValue="" />
                              </div>
                            </div>
                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="phone" className="form-label">Phone</label>
                                <input type="text" className="form-control" id='phone' name="phone" defaultValue={enquiryData ? enquiryData.phone : ''} />
                              </div>
                            </div>

                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="email" className="form-label">Email</label>
                                <input type="email" className="form-control" id='email' name="email" />
                              </div>
                            </div>

                            <div className="d-flex align-items-start gap-3 mt-4">
                              <button type="submit" className="btn btn-primary btn-label right ms-auto nexttab nexttab" ><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Save & Next</button>
                            </div>
                          </Row>
                        </form>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={2}>
                      <div className="" id="">
                        <div className="mb-4">
                          <h5 className="mb-1 text-center text-primary">Student other Details</h5>
                        </div>
                        <form onSubmit={(e) => handleOtherDetailsSubmit(e)}>
                          <input type="hidden" name="user_id" value={currentUser?.id} />
                          <Row>

                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="category" className="form-label">Category</label>
                                <CustomSelect name="category" isSearchable options={[
                                  { label: 'Genral', value: 'gen' },
                                  { label: 'OBC', value: 'obc' },
                                  { label: 'Backward Class', value: 'bc' },
                                  { label: 'SC', value: 'sc' },
                                  { label: 'ST', value: 'st' },
                                  { label: 'other', value: 'other' },
                                ]} />
                              </div>
                            </div>

                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="caste" className="form-label">Religion	</label>
                                <input type="text" className="form-control" id='caste' name="caste" />
                              </div>
                            </div>
                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="bloodgroup" className="form-label">Blood Group	</label>
                                <input type="text" className="form-control" id='bloodgroup' name="bloodgroup" />
                              </div>
                            </div>
                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="height" className="form-label">Height</label>
                                <input type="text" className="form-control" id='height' name="height" />
                              </div>
                            </div>
                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="weight" className="form-label">Weight</label>
                                <input type="text" className="form-control" id='weight' name="weight" />
                              </div>
                            </div>
                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="vision_left" className="form-label">Vision Left</label>
                                <input type="text" className="form-control" id='vision_left' name="vision_left" />
                              </div>
                            </div>
                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="vision_right" className="form-label">Vision Right</label>
                                <input type="text" className="form-control" id='vision_right' name="vision_right" />
                              </div>
                            </div>
                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="dentalhygeine" className="form-label">Dental Hygeine</label>
                                <input type="text" className="form-control" id='dentalhygeine' name="dentalhygeine" />
                              </div>
                            </div>

                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="adhaar" className="form-label">Adhar Number</label>
                                <input type="number" className="form-control" id='adhaar' name="adhaar" />
                              </div>
                            </div>

                            <div className="col-4 mt-2">
                              <div>
                                <label htmlFor="family_id">Family</label>
                                <CustomSelect name="family_id" id="family_id" isSearchable options={families?.map(i=>({value:i.id,label:`${i.father_name}`}))} />
                              </div>
                            </div>

                            <div className="col-2 mt-2">
                              <div>
                                <label htmlFor="family_id">Add new</label>
                                <input type="button" className="btn btn-outline-success form-control" onClick={()=>setFamilyModal(true)} id="button-addon2" value={"+"} />
                              </div>
                            </div>

                            <div className="d-flex align-items-start gap-3 mt-4">
                              <button type="submit" className="btn btn-primary btn-label right ms-auto nexttab nexttab" ><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Save & Next</button>
                            </div>
                          </Row>
                        </form>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey={3}>
                      <div className="" id="">
                        <div className="mb-4">
                          <h5 className="mb-1 text-center text-primary">Student Address</h5>
                        </div>
                        <form onSubmit={handleAddressFormSubmit}>
                          <input type="hidden" name="user_id" value={currentUser?.id} />
                          <input type="hidden" name="type" value="home" />
                          <Row>
                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="addr1" className="form-label">Address1</label>
                                <input type="text" className="form-control" id='addr1' name="addr1" />
                              </div>
                            </div>
                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="addr2" className="form-label">Address2</label>
                                <input type="text" className="form-control" id='addr2' name="addr2" />
                              </div>
                            </div>
                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="city" className="form-label">City</label>
                                <input type="text" className="form-control" id='city' name="city" />
                              </div>
                            </div>
                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="district" className="form-label">District	</label>
                                <input type="text" className="form-control" id='district' name="district" />
                              </div>
                            </div>
                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="state" className="form-label">State</label>
                                <input type="text" className="form-control" id='state' name="state" />
                              </div>
                            </div>
                            <div className="col-6 mt-2">
                              <div>
                                <label htmlFor="pin" className="form-label">Pin</label>
                                <input type="number" className="form-control" id='pin' name="pin" />
                              </div>
                            </div>
                          </Row>
                          <div className="d-flex align-items-start gap-3 mt-4">
                            <button type="submit" className="btn btn-primary btn-label right ms-auto nexttab nexttab" ><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Submit</button>
                          </div>
                        </form>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={4}>
                      <form onSubmit={handleFeeAdditionToSelectedCourseFee}>
                        <Row className="mb-2">
                          <Col xs={10}>
                              <div>
                                <label htmlFor="fees" className="form-label">Fees	</label>
                                <CustomSelect isSearchable name="fee_id"  id="fees" options={allFees.map(i=>({label:i.name,value:i.id}))} />
                              </div>
                          
                          </Col>
                          <Col xs={2}>
                            <div className="">
                              <label style={{opacity:0}} htmlFor="classes" className="form-label">Select Section	</label>
                              <button type="submit" className="form-control btn btn-soft-warning" >Add Fee</button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                      <form onSubmit={handleEnrollmentSubmit}>
                        <input type="hidden" name="user_id" value={currentUser?.id} />
                        <Row className="pb-4">
                          <Col xs={6} className="mb-3">
                              <div>
                                <label htmlFor="classes" className="form-label">Select A Class	</label>
                                <CustomSelect  isSearchable name="class_id" id="classes" options={courses?.map(i=>({label:i.name,value:i.id}))} 
                                  onChange={e=>{
                                    const newFees = courses?.filter(course=>course.id === e.value)[0]?.fees;
                                    setSelectedCourseFees([...selectedCourseFees,...newFees.filter(newFee=>!selectedCourseFees.some(existingFee => existingFee.id === newFee.id))])
                                  }} />
                              </div>
                          </Col>
                          <Col xs={6} className="mb-3">
                              <div>
                                <label htmlFor="classes" className="form-label">Select Section	</label>
                                <CustomSelect isSearchable name="section" id="classes" options={[
                                  {label:'A',value:'a'},
                                  {label:'B',value:'b'},
                                  {label:'C',value:'c'},
                                  {label:'D',value:'d'},
                                  {label:'E',value:'e'},
                                ]} />
                              </div>
                          </Col>
                          <Col xs={12} className="mb-3">
                            <TableResponsive 
                              showCustomOptionPage={false}
                              showFilter={false}
                              isPagination={false}
                              columns={fees_table_columns} 
                              data={selectedCourseFees} 
                              noRecord="Selected Course does not have a Fee" />
                          </Col>
                          <Col xs={12}>
                              <div className="d-flex align-items-start gap-3 mt-4">
                                <button type="submit" className="btn btn-primary btn-label right ms-auto nexttab nexttab" >
                                  <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Enroll
                                </button>
                              </div>
                          </Col>  
                        </Row>
                      </form>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Row>
            </CardBody>

            <Modal className="fade" centered={true} backdrop="static" show={familyModal} onHide={()=>setFamilyModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title><h5>Add New Family</h5></Modal.Title>
              </Modal.Header>
              <Modal.Body >
                <form onSubmit={handleFamilyFormSubmit}>
                  <div className="row g-3">
                    <div className="col-6">
                      <div>
                        <label htmlFor="father_name" className="form-label">Father Name</label>
                        <input type="text" className="form-control" id='father_name' name="father_name" defaultValue={enquiryData ? enquiryData.father_name : ''} />
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <label htmlFor="father_qualification" className="form-label">Father Qualification</label>
                        <input type="text" className="form-control" id='father_qualification' name="father_qualification" />
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <label htmlFor="father_occupation" className="form-label">Father Occupation</label>
                        <input type="text" className="form-control" id='father_occupation' name="father_occupation" />
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <label htmlFor="father_mobile" className="form-label">Father Mobile</label>
                        <input type="text" className="form-control" id='father_mobile' name="father_mobile" />
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <label htmlFor="father_aad" className="form-label">Father Aadhaar ID</label>
                        <input type="text" className="form-control" id='father_aad' name="father_aad" />
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <label htmlFor="father_photo" className="form-label">Father Photo</label>
                        <input type="file" className="form-control" id='father_photo' name="father_photo" />
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <label htmlFor="mother_name" className="form-label">Mother Name</label>
                        <input type="text" className="form-control" id='mother_name' name="mother_name" />
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <label htmlFor="mother_qualification" className="form-label">Mother Qualification</label>
                        <input type="text" className="form-control" id='mother_qualification' name="mother_qualification" />
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <label htmlFor="mother_occupation" className="form-label">Mother Occupation</label>
                        <input type="text" className="form-control" id='mother_occupation' name="mother_occupation" />
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <label htmlFor="mother_mobile" className="form-label">Mother Mobile</label>
                        <input type="text" className="form-control" id='mother_mobile' name="mother_mobile" />
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <label htmlFor="mother_aad" className="form-label">Mother Aadhaar ID</label>
                        <input type="text" className="form-control" id='mother_aad' name="mother_aad" />
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <label htmlFor="mother_photo" className="form-label">Mother Photo</label>
                        <input type="file" className="form-control" id='mother_photo' name="mother_photo" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-3">
                    <div className="hstack gap-2 justify-content-end">
                      <button type="submit" className="btn btn-primary">Save</button>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>

          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Admission
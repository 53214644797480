import React, { useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { course,} from '../../../helper/api_url'
import { Tooltip } from 'antd';
import { TextEditor } from '../../../components/common/TextEditor';
import {useModalHandler} from '../../../helper/custom_hook';
import CustomSelect from '../../../components/CustomSelect';

export function UpateCourseModal({ data, apiHandler }) {
    const {toggleModal,status} = useModalHandler();
    const courseType=[
        {value:'pre-primary',label:'Pre Primary'},
        {value:'primary',label:'Primary'},
        {value:'Secondary',label:'Secondary'},
        {value:'senior-secondary',label:'Senior Secondary'},
        {value:'other',label:'Other'},
    ];
    return (
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-success me-1'><i className="ri-pencil-fill"></i>  </button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Update Class</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={e => apiHandler.handleUpdate(e,course.update,toggleModal)}>
                        <div className="row g-3">
                            <input type="hidden" name="course_id" defaultValue={data.id} />
                            <div className="col-8">
                                <div>
                                    <label htmlFor="courseName" className="form-label">Name</label>
                                    <input type="text" className="form-control" id='courseName' name="name" defaultValue={data.name} placeholder="" />
                                </div>
                            </div>
                            <div className="col-4">
                                <div>
                                    <label htmlFor="code" className="form-label">Code</label>
                                    <input type="text" className="form-control" id='code' name='code'  defaultValue={data.code} placeholder=""  />
                                </div>
                            </div>
                            <div className="col-12">
                                <div>
                                    <label htmlFor="type" className="form-label">Type</label>
                                    <CustomSelect options={courseType} name='type'  id='type' />
                                </div>
                            </div>
                            
                            
                           
                            <div className="col-lg-12">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                    <button type="submit" className="btn btn-primary">update</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
import React from 'react'
import { useModalHandler } from '../../../helper/custom_hook';
import { Modal } from 'react-bootstrap';
import CustomSelect from '../../../components/CustomSelect';
import { swal } from '../../../helper/swal';
import { userQualification } from '../../../helper/api_url';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { useDispatch } from 'react-redux';

export const Qualification = ({data}) => {
    const dispatch=useDispatch();
    const {toggleModal,status} = useModalHandler();
    const handleSubmit=(e)=>{
        e.preventDefault();
        dispatch(setPreloader({ loader: true, message: 'please wait...' }))
        const formdata=new FormData(e.target);
        userQualification.add(formdata)
        .then((res)=>{swal.success(res.data.message);e.target.reset();dispatch(setPreloader({ loader: false, message: '' }))})
        .catch((err)=>{swal.error(err.response ? err.response.data.message : err.message);dispatch(setPreloader({ loader: false, message: '' }))})
    }
  return (
    <>
     <button type='button' onClick={toggleModal}  className="btn btn-sm btn-success btn-end"><i className="bx bx-plus"></i> Add Qualification</button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Qualification</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form onSubmit={handleSubmit}>
                        <div className="row g-3">
                            <div className="col-12">
                                <input type="hidden" name="user_id" id='user_id' defaultValue={data&&data.id} />
                                <div>
                                    <label htmlFor="name" className="form-label">Highest Qualification</label>
                                    <CustomSelect name="name" id="name" options={[
                                            { value: 'matriculation', label: 'matriculation' },
                                            { value: 'intermediate', label: 'intermediate' },
                                            { value: 'graduation', label: 'graduation' },
                                            { value: 'masters', label: 'masters' },
                                            { value: 'none', label: 'none' },
                                        ]} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div>
                                    <label htmlFor="board_university" className="form-label">Board Name</label>
                                    <input type='text' name='board_university' id='board_university' className='form-control' />
                                </div>
                            </div>
                            <div className="col-12">
                                <div>
                                    <label htmlFor="year" className="form-label">Passing Year</label>
                                    <input type='number' name='year' id='year' className='form-control' />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="marks" className="form-label">Marks</label>
                                    <input type='number' name='marks' id='marks' className='form-control' />
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="doc_name" className="form-label">Document Name</label>
                                    <input type='text' name='doc_name' id='doc_name' className='form-control' />
                                </div>
                            </div>
                            <div className="col-12">
                                <div>
                                    <label htmlFor="doc" className="form-label">Upload</label>
                                    <input type='file' name='doc' id='doc' className='form-control' />
                                </div>
                            </div>
                           
                            <div className="col-lg-12 mt-4">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                    <button type="submit" className="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
    </>
  )
}

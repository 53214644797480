import React, { useEffect, useRef, useState } from "react";
import logo from "./brand-logo.png";
import "./StudentIDCard.css";
import studentIdCard from "./studentIdCard.png";
import { student } from "../../helper/api_url";
import { swal } from "../../helper/swal";
// import { ASSETURL } from "../../config";
import { useReactToPrint } from "react-to-print";
import { setPreloader } from "../../features/Ui/uiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const IDCard = () => {
	const { studentID } = useParams();
	const [studentData, setStudentData] = useState({});
	const componentRef = useRef();
	const bullets = Array.from({ length: 35 });
	const dispatch = useDispatch()
	const ASSETURL = useSelector(state=>state.auth.ASSETURL);
	const App_logo = useSelector(state=>state.auth.LOGO);
	const APPNAME = useSelector(state=>state.auth.APPNAME);
	const client = useSelector(state=>state.auth.client);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	useEffect(() => {
		dispatch(setPreloader({ loader: true, message: 'Loading Please Wait ....' }))
		student
			.profile(studentID)
			.then((data) => {
				setStudentData(data.data.profile);
			})
			.catch((err) =>swal.error(err.response ? err.response.data.message : err.message))
			.finally(()=>dispatch(setPreloader({ loader: false, message: '' })));
	}, []);

	return (
		<>
			<div
				ref={componentRef}
				className="id-card"
				id="printable-area-st"
				style={{ backgroundImage: `url(${studentIdCard})`, margin: "0 auto" }}
			>
				<div className="id-card-header">
					<div className="school-name">{APPNAME}</div>
					<div className="school-logo">
						<img src={App_logo || logo} alt="School Logo" style={{borderRadius:'50%'}} />
					</div>
				</div>
				<div className="photo-placeholder">
					<div className="photo">
						<img
							width="120px"
							height="120px"
							className="rounded"
							src={`${ASSETURL}${studentData.avatar}`}
							alt="Student Logo"
						/>
					</div>
				</div>
				<div className="details">
					<div className="mb-3">
						{bullets.map((_, index) => (
							<span key={index} style={{ margin: "0 1px" }}>•</span>
						))}
					</div>
					{/* <div>..................</div> */}
					<div className="row w-100">
						<div className="col-4 ps-5">
							<strong>ID No</strong>
						</div>
						<div className="col-8">
							<strong>:</strong> #ST{studentData.id}
						</div>
					</div>
					<div className="row w-100">
						<div className="col-4 ps-5">
							<strong>Name</strong>
						</div>
						<div className="col-8">
							<strong>:</strong> {studentData?.first_name} {studentData?.last_name}
						</div>
					</div>
					<div className="row w-100">
						<div className="col-4 ps-5">
							<strong>E-mail</strong>
						</div>
						<div className="col-8">
							<strong>:</strong> {studentData.email}
						</div>
					</div>
					<div className="row w-100">
						<div className="col-4 ps-5">
							<strong>Phone</strong>
						</div>
						<div className="col-8">
							<strong>:</strong> {studentData.phone}
						</div>
					</div>
					<div className="row w-100">
						<div className="col-4 ps-5">
							<strong>Class</strong>
						</div>
						<div className="col-8">
							<strong>:</strong>{" "}
							{studentData?.courses?.map(c=>(<span className="me-2">{c.name}</span>))}
						</div>
					</div>
				</div>
				<div className="id-footer d-flex justify-content-between position-absolute px-3" style={{ bottom: "12px", color: "#fff",left:0,right:0 }}>
					<div className="website-footer"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-globe2" viewBox="0 0 16 16">
						<path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855q-.215.403-.395.872c.705.157 1.472.257 2.282.287zM4.249 3.539q.214-.577.481-1.078a7 7 0 0 1 .597-.933A7 7 0 0 0 3.051 3.05q.544.277 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9 9 0 0 1-1.565-.667A6.96 6.96 0 0 0 1.018 7.5zm1.4-2.741a12.3 12.3 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332M8.5 5.09V7.5h2.99a12.3 12.3 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.6 13.6 0 0 1 7.5 10.91V8.5zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741zm-3.282 3.696q.18.469.395.872c.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a7 7 0 0 1-.598-.933 9 9 0 0 1-.481-1.079 8.4 8.4 0 0 0-1.198.49 7 7 0 0 0 2.276 1.522zm-1.383-2.964A13.4 13.4 0 0 1 3.508 8.5h-2.49a6.96 6.96 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667m6.728 2.964a7 7 0 0 0 2.275-1.521 8.4 8.4 0 0 0-1.197-.49 9 9 0 0 1-.481 1.078 7 7 0 0 1-.597.933M8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855q.216-.403.395-.872A12.6 12.6 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.96 6.96 0 0 0 14.982 8.5h-2.49a13.4 13.4 0 0 1-.437 3.008M14.982 7.5a6.96 6.96 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008zM11.27 2.461q.266.502.482 1.078a8.4 8.4 0 0 0 1.196-.49 7 7 0 0 0-2.275-1.52c.218.283.418.597.597.932m-.488 1.343a8 8 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z" />
					</svg> {client?.subdomain}.unboxschool.in</div>
					<div className="insta-footer"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
						<path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
					</svg> {client?.business_email}</div>
				</div>
			</div>
			<div className="d-flex justify-content-center py-3">
				<button type="button" onClick={handlePrint} className="btn btn-soft-danger" >Print</button>
			</div>
		</>
	);
};

export default IDCard;

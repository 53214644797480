import React, { useEffect, useState } from 'react';
import { Modal, Row, Col} from 'react-bootstrap'
import { useModalHandler } from '../../../helper/custom_hook';
import { useDispatch } from "react-redux";
import CustomSelect from '../../../components/CustomSelect';
import { fee } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { setPreloader } from '../../../features/Ui/uiSlice';

function AddFeeToStudentsModal({data}) {
    const [allFee,setAllFee] = useState([]);
    const [currentFeeAmount,setCurrentFeeAmount] = useState(0);
    const {toggleModal,status} = useModalHandler();
    const dispatch = useDispatch();
    const handleAddFeesToAllStudents = e => {
        e.preventDefault();
        dispatch(setPreloader({ loader: true, message: "Please wait ..." }));
        const formData = new FormData(e.target);
        fee.impose_to_class_users(formData).then(r=>{
            swal.success(r.message,'Done');
            toggleModal();
        }).catch(e=>swal.error('Oppss...!',e.response?e.response.data.message:e.message))
        .finally(()=>dispatch(setPreloader({ loader: false, message: "" })))
    }
    useEffect(()=>{
        if(status)fee.list().then(r=>setAllFee(r.data.fee)).catch(e=>console.error(e));
    },[status])
    return (
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-warning mx-2'>
                <i className="ri-add-line align-bottom me-1"></i><span>Impose</span>
            </button>
           
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Add Fee To All Students {data.name}</h5></Modal.Title>
                </Modal.Header>
                <form onSubmit={handleAddFeesToAllStudents}>
                    <input type="hidden" name="course_id" id='course_id' defaultValue={data.id} />
                    <Modal.Body>
                        <Row>
                            <Col xs={12} className='mb-2'>
                                <div>
                                    <label htmlFor="fee_select" className="form-label">Select Fee</label>
                                    <CustomSelect 
                                        id="fee_select"
                                        options={allFee.map(f=>({label:f.name,value:f.id}))} 
                                        name="fee_id" 
                                        isSearchable 
                                        defaultValue={{label:'select A Fee',value:''}}
                                        onChange={e=>setCurrentFeeAmount(allFee.filter(f=>f.id==e.value)[0].amount)}  
                                        // setCurrentFeeAmount(allFee.filter(f=>f.id==e.value)[0].amount)
                                    />
                                </div>
                            </Col>
                            <Col xs={6} className='mb-2' >
                                <div>
                                    <label htmlFor="default_fee_amount" className="form-label">Fee Amount</label>
                                    <input className='form-control' type='number' step={0.01} id='default_fee_amount' onChange={()=>{}} value={currentFeeAmount} disabled />
                                </div>
                            </Col>
                            <Col xs={6} className='mb-2' >
                                <div>
                                    <label htmlFor="fee_amount" className="form-label">Final Fee Amount</label>
                                    <input className='form-control' type='number' step={0.01} id='fee_amount' name='fee_amount' onChange={()=>{}} value={currentFeeAmount}  />
                                </div>
                            </Col>
                            <Col xs={6} className='mb-2' >
                                <div>
                                    <label htmlFor="starting_date" className="form-label">Start Date</label>
                                    <input className='form-control' type='date' id='starting_date' name='starting_date' />
                                </div>
                            </Col>
                            <Col xs={6} className='mb-2' >
                                <div>
                                    <label htmlFor="last_payment_date" className="form-label">Last Date Of Payment</label>
                                    <input className='form-control' type='date' id='last_payment_date' name='last_payment_date'  />
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='submit' className='btn btn-primary'>Impose</button>
                    </Modal.Footer>
                </form>

            </Modal>
        </>
    )
}

export default AddFeeToStudentsModal
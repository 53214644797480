import React, { useRef, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Form, Nav, Row, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import CustomSelect from '../../../components/CustomSelect';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export const CreateReport = () => {
    const [activeStep, setActiveStep] = useState(1);
    const submark = [
        { key: 'sub0', subjectDisplayName: 'Computer', fullMark: '100', passingMark: '30' },
        { key: 'sub1', subjectDisplayName: 'Hindi (written)', fullMark: '80', passingMark: '24' },
        { key: 'sub2', subjectDisplayName: 'Hindi (oral)', fullMark: '20', passingMark: '06' },
        { key: 'sub3', subjectDisplayName: 'English (written)', fullMark: '80', passingMark: '24' },
        { key: 'sub4', subjectDisplayName: 'English (oral)', fullMark: '20', passingMark: '06' },
        { key: 'sub5', subjectDisplayName: 'Maths (written)', fullMark: '80', passingMark: '24' },
        { key: 'sub6', subjectDisplayName: 'Maths (oral)', fullMark: '20', passingMark: '06' },
        { key: 'sub7', subjectDisplayName: 'SansKrit/Urdu (written)', fullMark: '80', passingMark: '24' },
        { key: 'sub8', subjectDisplayName: 'SansKrit/Urdu (oral)', fullMark: '20', passingMark: '06' },
        { key: 'sub9', subjectDisplayName: 'Science (written)', fullMark: '80', passingMark: '24' },
        { key: 'sub10', subjectDisplayName: 'Science (project)', fullMark: '20', passingMark: '06' },
        { key: 'sub11', subjectDisplayName: 'S.st. (written)', fullMark: '80', passingMark: '24' },
        { key: 'sub12', subjectDisplayName: 'S.st. (project)', fullMark: '20', passingMark: '06' },
        { key: 'sub13', subjectDisplayName: 'G.K.M. Science (written)', fullMark: '50', passingMark: '15' },
        { key: 'sub14', subjectDisplayName: 'Drawing', fullMark: '50', passingMark: '15' },
        { key: 'sub15', subjectDisplayName: 'H.C.', fullMark: '50', passingMark: '15' },
        { key: 'sub16', subjectDisplayName: 'Physical', fullMark: '25', passingMark: '06' },
        { key: 'sub17', subjectDisplayName: 'Music', fullMark: '25', passingMark: '06' },
        { key: 'sub18', subjectDisplayName: 'H/Sci. Bonus', fullMark: '25', passingMark: '06' },
        { key: 'sub19', subjectDisplayName: 'School Activity', fullMark: '25', passingMark: '06' },

    ];
    const studentDetails = useRef({
        name: "",
        class: "",
        section: "",
        rollNo: "",
        parentName: ""
    });
    const handlePrintReportCard = (e) => {
        e.preventDefault();
        let reportCard = getReportCardData(e);
        const doc = new jsPDF('p', 'mm', 'a4');
        let count = 1;
        let totalFullMarks = reportCard.marks.filter(i => i.obtainedMark !== "").reduce((a, m) => a + parseFloat(m.fullMark), 0);
        let totalPassingMarks = reportCard.marks.filter(i => i.obtainedMark !== "").reduce((a, m) => a + parseFloat(m.passingMark), 0);
        let totalObtainedMarks = reportCard.marks.filter(i => i.obtainedMark !== "").reduce((a, m) => a + parseFloat(m.obtainedMark), 0);
        let divider = "---------------------------------------------------------------------------------------------------------------------------------------------------------";
        let tableHeadings = [
            { content: 'SL', styles: { halign: 'left' } },
            { content: 'Subject', styles: { halign: 'center' } },
            { content: 'Full Marks', styles: { halign: 'center' } },
            { content: 'Passing Marks', styles: { halign: 'center' } },
            { content: 'Obtained Marks', styles: { halign: 'center' } },
        ];
        doc.autoTable({
            head: [
                [{ content: "Gurukul Acedamy", colSpan: tableHeadings.length, styles: { halign: 'center' } }],
                [{ content: `School Address City 110092`, colSpan: tableHeadings.length, styles: { halign: 'center' } }]
            ],
            body: [
                [{
                    content: divider,
                    colSpan: tableHeadings.length,
                    styles: { halign: 'center' }
                }],
                [{ content: "Student Name : " + reportCard.studentDetails.name, colSpan: tableHeadings.length, styles: { halign: 'left' } }],
                [{ content: `class : ${reportCard.studentDetails.class} (${reportCard.studentDetails.section})`, colSpan: tableHeadings.length, styles: { halign: 'left' } }],
                [{ content: `Roll No. : ${reportCard.studentDetails.rollNo}`, colSpan: tableHeadings.length, styles: { halign: 'left' } }],
                [{ content: "Parent/Gardian Name : " + reportCard.studentDetails.parentName, colSpan: tableHeadings.length, styles: { halign: 'left' } }],
                [{
                    content: divider,
                    colSpan: tableHeadings.length,
                    styles: { halign: 'center' }
                }],
            ],
            startY: 10,
            theme: 'plain',
        });
        doc.autoTable({
            head: [tableHeadings],
            body: reportCard.marks.filter(i => i.obtainedMark !== "").map(d => [
                { content: count++, styles: { halign: 'left' } },
                { content: d.subjectDisplayName, styles: { halign: 'center' } },
                { content: d.fullMark, styles: { halign: 'center' } },
                { content: d.passingMark, styles: { halign: 'center' } },
                {
                    content: d.obtainedMark,
                    styles: d.obtainedMark >= d.passingMark
                        ? { halign: 'center' }
                        : { halign: 'center', textColor: [255, 0, 0] }
                }
            ]),
            startY: 74,
            startX: 10,
            theme: 'striped',
            foot: [
                [
                    { content: 'Total', styles: { halign: 'center' }, colSpan: 2 },
                    { content: totalFullMarks, styles: { halign: 'center' } },
                    { content: totalPassingMarks, styles: { halign: 'center' } },
                    {
                        content: totalObtainedMarks,
                        styles: totalObtainedMarks >= totalPassingMarks
                            ? { halign: 'center' }
                            : { halign: 'center', textColor: [255, 0, 0] }
                    }
                ],
            ],
        });
        doc.save(reportCard.studentDetails.name + '_MarkSheet.pdf');
    }
    const getReportCardData = (e) => {
        let formData = new FormData(e.target);
        const reportCard = {
            studentDetails: studentDetails.current,
            marks: Array.from(formData.entries()).reduce((acc, [key, value]) => {
                acc.push({ ...submark.filter(i => i.key === key)[0], obtainedMark: value });
                return acc;
            }, [])
        }
        return reportCard
    }
    const [validated, setValidated] = useState(false);
    const handleSaveStudentDetails = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        let formData = new FormData(e.target);
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }else{
            Array.from(formData.entries()).reduce((acc, [key, value]) => {
                studentDetails.current[key] = value;
            }, {});
            console.log(studentDetails);
            setActiveStep(2);
        }
        setValidated(true);
    };
   
return (
    <>
        <Row>
            <Col>
                <Card>
                    <CardHeader className="d-flex align-items-center justify-content-between">
                        <h5 className="card-title mb-0">Report Management</h5>
                        <div>
                            <Link to="/reportmanage" className='btn btn-soft-dark add-btn waves-effect me-2'><i className="ri-contacts-fill me-2"></i>Back</Link>
                            <button className='btn btn-soft-success add-btn waves-effect'><i className="ri-add-line align-bottom me-1"></i><span>New Report</span></button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <Row className="g-3 mx-lg-5">
                            <Tab.Container activeKey={activeStep}>
                                <Tab.Content>
                                    <Tab.Pane eventKey={1}>
                                        <Form noValidate validated={validated} onSubmit={handleSaveStudentDetails} >
                                            <div className="" id="">
                                                <div className="mb-4">
                                                    <h5 className="mb-1 text-center text-primary">Student Details</h5>
                                                </div>
                                                <Row className="mt-3">
                                                    <div className="col-6 mb-2">
                                                        <Form.Group controlId="validationCustom01">
                                                            <Form.Label htmlFor="name" className="form-label">Student Name</Form.Label>
                                                            <Form.Control required type="text" className="form-control" id='name' name="name"/>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-6 mb-2">
                                                        <Form.Group>
                                                            <Form.Label htmlFor="name" className="form-label">Roll Number</Form.Label>
                                                            <Form.Control required type="text" className="form-control" id='rollNo' name="rollNo" />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-6 mb-2">
                                                        <Form.Group>
                                                            <Form.Label htmlFor="class" className="form-label">Class</Form.Label>
                                                            <CustomSelect name='class' id="class" options={[
                                                                { label: 'One', value: 'one' },
                                                                { label: 'Two', value: 'two' },
                                                                { label: 'Three', value: 'three' },
                                                                { label: 'Four', value: 'four' },
                                                            ]} required />
                                                            
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-6 mb-2">
                                                        <Form.Group>
                                                            <Form.Label htmlFor="section" className="form-label">Section</Form.Label>
                                                            <CustomSelect name='section' id="section" options={[
                                                                { label: 'A', value: 'A' },
                                                                { label: 'B', value: 'B' },
                                                                { label: 'C', value: 'C' },
                                                                { label: 'D', value: 'D' },
                                                            ]} required/>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-12 mb-2">
                                                        <Form.Group>
                                                            <Form.Label htmlFor="parentName" className="form-label">Parent Name</Form.Label>
                                                            <Form.Control required type="text" className="form-control" id='parentName' name="parentName" />
                                                        </Form.Group>
                                                    </div>
                                                </Row>
                                                <div className="d-flex align-items-start gap-3 mt-4">
                                                    <button type="submit" className="btn btn-primary btn-label right ms-auto nexttab nexttab" ><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Next</button>
                                                </div>
                                            </div>
                                        </Form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={2}>
                                        <form onSubmit={handlePrintReportCard}>
                                            <div className="" id="">
                                                <div className="mb-4">
                                                    <h5 className="mb-1 text-center text-primary">Mark Details</h5>
                                                </div>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">SrN</th>
                                                            <th scope="col">Subject</th>
                                                            <th scope="col">Full Mark</th>
                                                            <th scope="col">Passing Mark</th>
                                                            <th scope="col">Obtained Mark</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {submark.map((item, idx) => (
                                                            <tr key={idx}>
                                                                <th scope="row">{idx + 1}</th>
                                                                <td>{item.subjectDisplayName} </td>
                                                                <td>{item.fullMark}</td>
                                                                <td>{item.passingMark}</td>
                                                                <td><input type='number' name={item.key} className='form-control' placeholder={item.subjectDisplayName} /></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>

                                                </table>
                                                <div className="d-flex align-items-start gap-3 mt-4">
                                                    <button type="button" onClick={() => setActiveStep(1)} className="btn btn-primary btn-label left ms-auto nexttab nexttab" ><i className="ri-arrow-left-line label-icon align-middle fs-16 ms-2"></i>Prev</button>
                                                    <button type="submit" className="btn btn-success" >Print</button>
                                                </div>
                                            </div>
                                        </form>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
)
}

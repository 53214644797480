import React,{useEffect, useState} from 'react'
import { Modal} from 'react-bootstrap'
import { swal } from '../../../helper/swal';
import { fee,course } from '../../../helper/api_url';
import { useModalHandler } from '../../../helper/custom_hook';
export function AddFeesModal({data, apiHandler }) {
    const {toggleModal,status} = useModalHandler();
    const [singleData,setSingleData]=useState();
    const [tableData,setTableData]=useState([]);
    useEffect(()=>{
        if(status)
        fee.list().then(r=>setTableData(r.data[Object.keys(r.data)[0]])).catch(err=>swal.error(err.response?err.response.data.message:err.message))
      },[status]);
    const handlegetId = (e) => {
        e.target.value?fee.fetchSingle(e.target.value)
        .then(r=>setSingleData(r.data.fee),document.querySelector("#showAmount").className='d-block')
        .catch(err=>swal.error(err.response?err.response.data.message:err.message)):document.querySelector("#showAmount").className='d-none'
    };
    const handleSubmit = e => {
        e.preventDefault();
        const course_id=document.querySelector("#course_id").value;
        const fee_id=document.querySelector("#fee_id").value;
        course.addFee(course_id,fee_id)
        .then(res => {
                toggleModal(); swal.success(res.data.message);
        })
        .catch(err => { swal.error(err.response ? err.response.data.message : err.message) })
    }
    return (
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-dark'>
                <i className="ri-add-line align-bottom me-1"></i>
                <span>Fees</span>
            </button>
           
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Add Fee</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={e =>handleSubmit(e)}>
                        <div className="row g-3">
                            <div className="col-12">
                                <input type="hidden" name="course_id" id='course_id' defaultValue={data.id} />
                                <div>
                                    <label htmlFor="cname" className="form-label">Class Name</label>
                                    <input type="text" className="form-control" id='cname' defaultValue={data.name} readOnly/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <label htmlFor="fees_id" className="form-label">Fee Name</label>
                                    <select id="fee_id" name='fee_id' className='form-control' onChange={handlegetId}>
                                        <option value="">--Select Fee Name--</option>
                                        {tableData.length ? tableData.map((fee, idx) => (<option key={idx} value={fee.id}>{fee.name}</option>)) : (<option disabled >No Fees Found</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-6">
                                <div id='showAmount' className='d-none'>
                                    <label htmlFor="type" className="form-label">Total Amount</label>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text bg-soft-info"><i className='bx bx-rupee' /></span>
                                        <input type="text" className="form-control" defaultValue={singleData?singleData.amount:''}  />
                                        <span className="input-group-text">{singleData?singleData.type:''}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-4">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                    <button type="submit" className="btn btn-primary">Add</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    );
}
import { useEffect, useState } from "react";
import { Card, CardBody, Badge, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { swal } from "../../../../helper/swal";
import { fee } from "../../../../helper/api_url";
import { useQueryClient } from '@tanstack/react-query';

export default function FamilyDuesFeesPaymentModal({ row_data }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedDues, setSelectedDues] = useState([]);
    const [discount, setDiscount] = useState(null);
    const [paymentMode, setPaymentMode] = useState('cash');
    const [payerName, setPayerName] = useState('');
    const [payerPhone, setPayerPhone] = useState('');
    const [payerEmail, setPayerEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const queryClient = useQueryClient();

    useEffect(() => {
        if (show && row_data?.dues) {
            setSelectedDues(row_data.dues.map(due => due.id));
            setPayerName(row_data?.father_name);
            setPayerPhone(row_data?.father_mobile);
            setPayerEmail(row_data?.father_email);
        }
    }, [show, row_data]);

    const handleRemoveDue = (dueId) => {
        setSelectedDues(prev => prev.filter(id => id !== dueId));
    };

    const calculateTotalDues = () => {
        return row_data?.dues
            ?.filter(due => selectedDues.includes(due.id))
            ?.reduce((total, due) => total + due.dues_amount, 0) || 0;
    };
    const handlePaySelectedDues = async () => {
        setIsSubmitting(true);
        const payload = {
            due_fee_ids: selectedDues,
            amount: calculateFinalAmount(),
            payment_mode: paymentMode,
            payer_name: payerName,
            payer_phone: payerPhone,
            payer_email: payerEmail,
            discount: discount
        };
        fee.bulkPayment(payload).then(res => {
            queryClient.setQueryData(['familydues'], (oldData) => {
                if (!oldData) return oldData;
                const dues = oldData.data.dues.map(d=>({...d,due_fee:d.due_fee.filter(due=>!selectedDues.includes(due.id))}));
                return {...oldData,data: {...oldData.data,dues: dues}};
            });
            
            handleClose();
            swal.success('Payment processed successfully');
        })
            .catch(error => {
                console.log(error);
                swal.error(error.response?.data?.message || 'Failed to process payment');
            })
            .finally(() => setIsSubmitting(false));
    };

    const calculateFinalAmount = () => {
        if (discount === null) return calculateTotalDues();
        const totalDues = calculateTotalDues();
        const discountAmount = (totalDues * discount) / 100;
        return totalDues - discountAmount;
    };

    return (
        <div>
            <button
                onClick={handleShow}
                className='btn btn-sm btn-soft-success d-inline-flex align-items-center me-2'
            >
                <i className="ri-secure-payment-fill fs-5 me-2" />Pay
            </button>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Pay Dues</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <div
                        className="d-flex flex-column gap-2 overflow-auto"
                        style={{
                            maxHeight: "50vh",
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                            "&::WebkitScrollbar": { display: "none" }
                        }}
                    >
                        {row_data?.dues?.map((due, index) => (
                            selectedDues.includes(due.id) && (
                                <Card key={index} className="border-0 shadow-sm mb-2">
                                    <CardBody>
                                        {/* Student Info Header */}
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div>
                                                <h6 className="mb-1 text-primary">
                                                    {due.first_name} {due.last_name}
                                                </h6>
                                                <span className="text-muted small">
                                                    <i className="ri-book-line me-1"></i>
                                                    {due.courses}
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <Badge bg={due.dues_amount > 0 ? 'soft-danger' : 'success'} className="px-3 py-2">
                                                    {due.dues_amount > 0 ? 'Pending' : 'Paid'}
                                                </Badge>
                                                <button
                                                    className="btn btn-sm btn-danger"
                                                    onClick={() => handleRemoveDue(due.id)}
                                                >
                                                    <i className="ri-close-line"></i>
                                                </button>
                                            </div>
                                        </div>

                                        {/* Fee Details */}
                                        <div className="row g-3">
                                            <Col lg={4}>
                                                <div className="bg-light rounded py-2 px-3">
                                                    <small className="text-muted d-block">Fee Name</small>
                                                    <span className="fw-medium">{due.name}</span>
                                                </div>
                                            </Col>
                                            <div className="col-6 col-lg-4">
                                                <div className="bg-light rounded py-2 px-3">
                                                    <small className="text-muted d-block">Total Amount</small>
                                                    <span className="fw-medium">₹{due.imposed_amount}</span>
                                                </div>
                                            </div>
                                            <div className="col-6 col-lg-4">
                                                <div className="bg-light rounded py-2 px-3">
                                                    <small className="text-muted d-block">Paid Amount</small>
                                                    <span className="fw-medium">₹{due.paid_amount || 0}</span>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Current Dues */}
                                        <div className="mt-3 pt-3 border-top">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span className="text-muted">Current Dues</span>
                                                <h5 className={`mb-0 ${due.dues_amount > 0 ? 'text-danger' : 'text-success'}`}>
                                                    {due.dues_amount > 0 ? `₹${due.dues_amount}` : 'Fully Paid'}
                                                </h5>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            )
                        ))}
                    </div>
                    <Modal.Footer className="border-top pt-3">
                        <div className="w-100">
                            {/* Payment Details Row */}
                            <div className="row g-3 mb-3">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-label">Payment Mode</label>
                                        <select
                                            className="form-select"
                                            value={paymentMode}
                                            onChange={(e) => setPaymentMode(e.target.value)}
                                        >
                                            <option value="cash">Cash</option>
                                            <option value="bank_transfer">Bank Transfer</option>
                                            <option value="online_payment">Online Payment</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-label">Discount (%)</label>
                                        <input
                                            type="tel"
                                            className="form-control"
                                            value={discount === null ? '' : discount}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/[^0-9]/g, '');
                                                const numValue = Number(value);
                                                setDiscount(Math.min(100, Math.max(0, numValue)));
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Payer Information */}
                            <div className="row g-3 mb-3">
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="form-label">Payer Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={payerName}
                                            onChange={(e) => setPayerName(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="form-label">Payer Phone</label>
                                        <input
                                            type="tel"
                                            className="form-control"
                                            value={payerPhone}
                                            onChange={(e) => setPayerPhone(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="form-label">Payer Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            value={payerEmail}
                                            onChange={(e) => setPayerEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Total Amount and Pay Button */}
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="text-muted mb-1">
                                        Subtotal: <span className="fw-medium">₹{calculateTotalDues()}</span>
                                        {discount > 0 && (
                                            <>
                                                <span className="mx-2">|</span>
                                                Discount: <span className="fw-medium text-success">{discount}%</span>
                                            </>
                                        )}
                                    </div>
                                    <h5 className="mb-0">
                                        Final Amount: <span className="text-primary">₹{calculateFinalAmount()}</span>
                                    </h5>
                                </div>
                                <button
                                    className="btn btn-soft-success"
                                    disabled={selectedDues.length === 0 || !payerName || !payerPhone || isSubmitting}
                                    onClick={handlePaySelectedDues}
                                >
                                    {isSubmitting ? (
                                        <>
                                            <span className="spinner-border spinner-border-sm me-1" />
                                            Processing...
                                        </>
                                    ) : (
                                        <>
                                            <i className="ri-secure-payment-line me-1" />
                                            Pay Selected Dues
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </div >
    )
}
import { useState } from "react";

const Avatar = ({ src, name, className }) => {
    const [imageError, setImageError] = useState(false);
    const firstLetter = name?.charAt(0)?.toUpperCase() || '?';

    if (imageError) {
        return (
            <div 
                className={`d-inline-flex align-items-center justify-content-center rounded-circle bg-soft-primary text-primary ${className}`}
                style={{
                    width: '32px',
                    height: '32px',
                    fontSize: '14px',
                    fontWeight: '500'
                }}
            >
                {firstLetter}
            </div>
        );
    }

    return (
        <img 
            className={`${className} rounded-circle header-profile-user`}
            style={{objectFit: 'cover', width: '32px', height: '32px'}} 
            src={src}
            alt={`${name}'s Avatar`}
            onError={() => setImageError(true)}
        />
    );
};
export default Avatar;

import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Table, Card, CardBody, CardHeader, CardFooter } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setPreloader } from '../../../../features/Ui/uiSlice';
import { student } from '../../../../helper/api_url';
import { swal } from '../../../../helper/swal';
import { useReactToPrint } from 'react-to-print';
import "./printable.css";

function AdmitCardPrintable() {
    const { studentID } = useParams();
    const [studentData, setStudentData] = useState({});
	const componentRef = useRef();
    const dispatch = useDispatch()
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});
    useEffect(() => {
		dispatch(setPreloader({ loader: true, message: 'Loading Please Wait ....' }))
		student.profile(studentID)
        .then((data) => setStudentData(data.data.profile))
        .catch((err) =>swal.error(err.response ? err.response.data.message : err.message))
        .finally(()=>dispatch(setPreloader({ loader: false, message: '' })));
	}, []);

    return (
        <>
            <Container className="mt-4">
                <div className='mb-4 d-flex justify-content-center'>
                    <button type='button' onClick={handlePrint} className='btn btn-soft-danger'>Print</button>
                </div>
                <Card ref={componentRef} id="printable-area">
                    <CardHeader className="text-center">
                        <img
                            src="https://via.placeholder.com/1500x200?text=School+Name+Header+with+logo"
                            alt="Placeholder"
                            className="img-fluid rounded"
                        />
                    </CardHeader>
                    <CardBody className='px-4'>
                        <Row className="bg-primary text-white text-center py-2 mb-3 rounded">
                            <Col>
                                <h5 className='m-0 text-white'>ADMIT CARD (Final Examination 2023-24)</h5>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col xs="6">
                                <p><strong>Student name:</strong> {studentData?.first_name} {studentData?.last_name}</p>
                                {studentData?.courses?.length?(
                                    <>
                                        <p><strong>Class:</strong> {studentData?.courses?.map(c=>(<span className="me-2">{c.name}</span>))}</p>
                                        <p><strong>Roll no.:</strong> {studentData?.courses[0]?.pivot?.id}</p>
                                    </>
                                ):(
                                    <>
                                        <p><strong>Class:</strong> - </p>
                                        <p><strong>Roll no.:</strong> -</p>
                                    </>
                                )}
                            </Col>
                            <Col xs="6">
                                <p><strong>Father name:</strong> SUJIT KUMAR JHA</p>
                                <p><strong>Mother name:</strong> ARCHANA KUMARI</p>
                                <p><strong>Section:</strong> A</p>
                            </Col>
                        </Row>
                        <Row className="bg-primary rounded text-center py-2 mb-3">
                            <Col>
                                <h5 className='m-0 text-white'>Subject details</h5>
                            </Col>
                        </Row>
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Subject name</th>
                                    <th>Date & Time</th>
                                    <th>Shift</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Maths</td>
                                    <td>15-03-2024 09:30 AM - 11:30 AM</td>
                                    <td>Shift 1</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>English</td>
                                    <td>16-03-2024 09:30 AM - 11:30 AM</td>
                                    <td>Shift 1</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>English Oral</td>
                                    <td>16-03-2024 12:00 AM - 01:00 PM</td>
                                    <td>Shift 2</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Hindi</td>
                                    <td>18-03-2024 09:30 AM - 11:30 AM</td>
                                    <td>Shift 1</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Hindi Oral</td>
                                    <td>18-03-2024 12:00 AM - 01:00 PM</td>
                                    <td>Shift 2</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Drawing</td>
                                    <td>19-03-2024 09:30 AM - 11:30 PM</td>
                                    <td>Shift 1</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>SST</td>
                                    <td>20-03-2024 09:30 AM - 11:30 AM</td>
                                    <td>Shift 1</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>G.K</td>
                                    <td>21-03-2024 09:30 AM - 11:30 AM</td>
                                    <td>Shift 1</td>
                                </tr>
                               
                                <tr>
                                    <td>12</td>
                                    <td>Science</td>
                                    <td>22-03-2024 09:30 AM - 11:30 AM</td>
                                    <td>Shift 1</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Row className="text-center bg-primary rounded py-2 mb-3">
                            <Col>
                                <p className='m-0 text-white'>Examination venue: Raghunathpur Khurd, Madhuban, Near Bhagwati Asthan, Muzaffarpur</p>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col xs="6">
                                <p><strong>Reporting time:</strong> 9:00 AM</p>
                                <p><strong>Class teacher's signature:</strong> .........................</p>
                            </Col>
                            <Col xs="6">
                                <p><strong>Dispersal time:</strong> 2:00 PM</p>
                                <p><strong>Accountant's signature:</strong> .........................</p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </>
    );
}

export default AdmitCardPrintable
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { swal } from '../../../helper/swal';
import { fee, student } from '../../../helper/api_url';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Modal, Row } from 'react-bootstrap';
import { TableResponsive } from '../../../components/common/TableResponsive';
import { formatDate } from '../../../helper/formatDate';

export const StudentDuesFees = () => {
    const { sid } = useParams();
    const dispatch = useDispatch();
    const [studentFeeInfo, setStudentFeeInfo] = useState([]);
    const [studentData, setStudentData] = useState(null);
    useEffect(() => {
        ; (async () => {
            try {
                dispatch(setPreloader({ loader: true, message: 'Loading Please Wait ....' }));
                const res = await student.profile(sid);
                setStudentData(res.data.profile);
                const data = await fee.StudentInfoFee(sid);
                setStudentFeeInfo(data.data.student_fee_info);
            } catch (err) {
                swal.error(err.response ? err.response.data.message : err.message);
            } finally {
                dispatch(setPreloader({ loader: false, message: '' }));
            }
        })();
    }, []);

    const columns = useMemo(() => [
        {
            Header: "Fees name",
            accessor: "name",
            HeaderClass: '',
            DataClass: '',
        },
        {
            Header: "Total Amount",
            accessor: "imposed_amount",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
        },
        {
            Header: "Paid Amount",
            accessor: "fees_payment",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: ({ row }) => {
                const feesPayment = row.original.fees_payment;
                if (feesPayment && feesPayment.length > 0) {
                    return (
                        <>
                            {feesPayment.map((payment, index) => (
                                <span className='badge badge-outline-success mx-1' key={index}>{payment.paid_amt}</span>
                            ))}
                        </>

                    );
                }
                return "-";
            }
        },
        {
            Header: "Total Dues",
            accessor: "total_dues",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: ({ row }) => {
                const imposedAmount = row.original.imposed_amount;
                const lastPaymentDateObject = new Date(row.original.last_payment_date);
                const now = Date.now();
                if (lastPaymentDateObject < now) {
                    const feesPayment = row.original.fees_payment || [];
                    const totalPaid = feesPayment.reduce((total, payment) => total + (payment.paid_amt || 0), 0);
                    const totalDues = imposedAmount - totalPaid;
                    return totalDues > 0 ? <span className={'text-danger'}>{totalDues}</span> : <span className='text-success'>Fully Paid</span>;
                } else return "-"


            }
        },
        {
            Header: "Last Date of Payment",
            HeaderClass: 'text-end',
            DataClass: 'text-end',
            Cell: (cell) => {
                return (<span>{formatDate(cell.row.original.last_payment_date)}</span>)
            }
        },
        {
            Header: "Action",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => {
                return (<span>
                    <button
                        className='btn btn-sm btn-outline-danger'
                        onClick={() => setFeeDueToBeDeleted(cell.row.original)}
                    ><i className="bx bx-trash"></i></button>
                </span>)
            }
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                const imposedAmount = row.imposed_amount;
                const lastPaymentDateObject = new Date(row.last_payment_date);
                const now = Date.now();

                let dues = '-';
                if (lastPaymentDateObject < now) {
                    const feesPayment = row.fees_payment || [];
                    const totalPaid = feesPayment.reduce((total, payment) => total + (payment.paid_amt || 0), 0);
                    const totalDues = imposedAmount - totalPaid;
                    dues = totalDues > 0 ? <span className={'text-danger'}>{totalDues}</span> : <span className='text-success'>Fully Paid</span>;
                }

                const feesPayment = row.fees_payment || [];
                const totalPaid = feesPayment.reduce((total, payment) => total + (payment.paid_amt || 0), 0);
                const totalDues = imposedAmount - totalPaid;

                return (
                    <Card className="border shadow-sm">
                        <CardBody>
                            <div className="d-flex flex-column">
                                {/* Fee Name and Amount Section */}
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h5 className="mb-0">{row.name}</h5>
                                    <span className="badge bg-info px-3 py-2 rounded-pill">
                                        ₹{imposedAmount}
                                    </span>
                                </div>

                                {/* Payment Details */}
                                <div className="d-flex flex-wrap gap-2 mb-3">
                                    <div className="bg-soft-success px-3 py-2 rounded">
                                        <small className="text-muted d-block">Paid Amount</small>
                                        <span className="fw-semibold">
                                            {feesPayment.length > 0 ? (
                                                feesPayment.map((payment, index) => (
                                                    <span key={index} className="me-1">
                                                        ₹{payment.paid_amt}
                                                        {index < feesPayment.length - 1 ? " +" : ""}
                                                    </span>
                                                ))
                                            ) : (
                                                "₹0"
                                            )}
                                        </span>
                                    </div>

                                    <div className={`px-3 py-2 rounded ${totalDues > 0 ? 'bg-soft-danger' : 'bg-soft-success'}`}>
                                        <small className="text-muted d-block">Dues</small>
                                        <span className="fw-semibold">
                                            {dues}
                                        </span>
                                    </div>
                                </div>

                                {/* Last Payment Date */}
                                <div className="d-flex justify-content-between border-top pt-2 align-items-center">
                                    <div className="">
                                        <small className="text-muted">Last Payment Date:</small>
                                        <span className="ms-2">{formatDate(lastPaymentDateObject)}</span>
                                    </div>
                                    <button
                                        className='btn btn-sm btn-outline-danger'
                                        onClick={() => setFeeDueToBeDeleted(row)}
                                    ><i className="bx bx-trash"></i></button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                )

            }
        }
    ]);
    const [feeDueToBeDeleted, setFeeDueToBeDeleted] = useState(null);
    const handleDeleteFeeDue = (fee_id) => {
        dispatch(setPreloader({ loader: true, message: 'Deleting Fees Please Wait ....' }));
        fee.deleteFeeDue(fee_id).then(res=>{
            swal.success(res.data.message);
            setFeeDueToBeDeleted(null);
            setStudentFeeInfo(studentFeeInfo.filter(fee => fee.id !== fee_id));
        }).catch(err=>swal.error(err.response ? err.response.data.message : err.message))
        .finally(()=>dispatch(setPreloader({ loader: false, message: '' })));
    }
    return (
        <>
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between" style={{ flexDirection: 'column' }}>
                            <h2>{studentData?.first_name} {studentData?.last_name}</h2>
                            <h5 className="card-title mb-0">Fees Information</h5>
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive
                                customPageSize={1000}
                                isPagination={false}
                                showCustomOptionPage={false}
                                columns={columns}
                                data={studentFeeInfo} noRecord="No Record Found"
                            />
                        </CardBody>
                        <CardFooter className='d-flex align-items-center justify-content-between w-100'>
                            <div>
                                <p className='d-inline border me-4 p-2 rounded bg-soft-primary'>
                                    Total Fees : {studentFeeInfo.reduce((a, f) => a + parseFloat(f.imposed_amount), 0)}
                                </p>
                                <p className='d-inline border p-2 rounded bg-soft-success' >
                                    Total Paid : {studentFeeInfo.reduce((acc, fee) => {
                                        const total_payment = fee.fees_payment.reduce((totalPayment, fp) => parseFloat(totalPayment) + parseFloat(fp.paid_amt), 0);
                                        return acc + total_payment;
                                    }, 0)}
                                </p>
                            </div>
                            <p className='border p-2 rounded bg-soft-danger'>
                                Overdues : {
                                    studentFeeInfo.reduce((acc, fee) => {
                                        const lastPaymentDateObject = new Date(fee.last_payment_date);
                                        const now = Date.now();
                                        if (lastPaymentDateObject < now) {
                                            const total_payment = fee.fees_payment.reduce((totalPayment, fp) => parseFloat(totalPayment) + parseFloat(fp.paid_amt), 0);
                                            return parseFloat(acc) + parseFloat(fee.imposed_amount) - parseFloat(total_payment);
                                        } else return acc + 0;

                                    }, 0)
                                }
                            </p>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            <Modal
                className="fade"
                size=''
                centered={true}
                backdrop="static"
                show={feeDueToBeDeleted}
                onHide={() => setFeeDueToBeDeleted(null)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Dues Delete ?</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this fee due?</p>
                    <p className='text-warning mb-2'>{feeDueToBeDeleted?.name}</p>
                    <p className='text-danger fs-5'> Fees Dues/Amount : {feeDueToBeDeleted?.imposed_amount - feeDueToBeDeleted?.fees_payment.reduce((acc,f)=>acc+f.paid_amt,0)}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-sm btn-outline-secondary' onClick={() => setFeeDueToBeDeleted(null)}>Cancel</button>
                    <button className='btn btn-sm btn-outline-danger' onClick={() => handleDeleteFeeDue(feeDueToBeDeleted.id)}>Delete</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

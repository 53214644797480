import React from 'react'
import { useNavigate } from 'react-router-dom';

export const FeeButton = (id) => {
    const navigate=useNavigate();
    const handleClick=(sid)=>{
        navigate(`/student/${sid.id}/fee`)
    }
    return (
        <>
            <button onClick={()=>handleClick(id)} className='btn btn-soft-dark add-btn waves-effect mx-1'>
                <i className="ri-coins-line align-bottom me-1"></i>
                <span>Fee Dues</span>
            </button>
        </>
    )
}

import React from 'react'
import {useNavigate } from 'react-router-dom';
import { Modal,Row,Col} from 'react-bootstrap'
import {ASSET_URL } from '../../../helper/api_url'
import { useModalHandler} from '../../../helper/custom_hook';
import ModalProfileImage from '../../../components/common/ModalProfileImage';
export function ViewEnquiryModal(data) {
    const navigate = useNavigate();
    const {toggleModal,status} = useModalHandler();
    const viewimage = ASSET_URL + data.view.avatar;
    const trial_end = data.view.tryal_date != null ? new Date(data.view.tryal_date) : null;
    if (trial_end != null) trial_end.setDate(trial_end.getDate() + data.view.tryal_period);
    const handleReport = (data) => {
        navigate('/report', { state: { data } });
    }
    return (
        <>
            {data.children ? (<div onClick={toggleModal}>{data.children}</div>) : (
                <button onClick={toggleModal} className='btn btn-sm btn-soft-info me-1'><i className="ri-eye-fill" /></button>
            )}
            <Modal className="fade" centered={true} show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Enquiry By {data.view?.created_by?.first_name}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalProfileImage viewimage={viewimage} />
                    <Row className='px-2 text-center mt-2'>
                        <Col xs={12} >
                            <ul className="list-group">
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Candidate Name </span></Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-nowrap'> {data.view.name} </span>
                                            {(trial_end != null)
                                                ? (trial_end > Date.now()
                                                    ? (<span className='badge badge-soft-success px-1 ms-1'>On Trail</span>)
                                                    : (<span className='badge badge-soft-danger px-1 ms-1'>Trail Expired</span>))
                                                : null}
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Father's Name </span> </Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-nowrap'> {data.view.father_name} </span>
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Class </span> </Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-nowrap'> {data.view.course.code} </span>
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Phone </span> </Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-nowrap'> {data.view.phone} </span>
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Whatsapp </span> </Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-nowrap'> {data.view.whatsapp} </span>
                                        </Col>
                                    </Row>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className='w-100 d-flex align-items-center justify-content-between px-2 pb-3'>
                        <a href={`tel:${data.view.phone}`} className="btn btn-soft-info" >
                            <i className='bx bx-phone-outgoing' />
                        </a>
                        <button onClick={() => handleReport(data.view)} className="btn btn-sm btn-outline-success">View Detailed Profile</button>
                        <a href={`whatsapp://send?phone=+91${data.view.whatsapp}&text=Hello%20${data.view.name}%0A%0A`} className="btn ms-2 btn-soft-success" >
                            <i className='bx bxl-whatsapp' />
                        </a>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}